import { Product } from "@mgi-labs/preview";
import { Book } from "../Domain/book";

export type ProductContent = Product & {
  type?: string;
};

export function createProductFromBook(
  book: Book,
  productId: number
): ProductContent | undefined {
  const product = book.projects?.find((content) => content.id === productId);
  if (product) {
    return {
      ...product.product,
      type: product.type,
    };
  } else {
    return undefined;
  }
}
