import { PaperMaterial } from "@mgi-labs/preview";
import {
  DEFAULT_FOIL_MAPPING,
  FoilColorName,
  FoilMapping,
} from "@mgi-labs/preview/dist/Domain/Foil";
import classNames from "classnames";
import React, { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import black from "../../images/foilimg/black.jpg";
import blue from "../../images/foilimg/blue.jpg";
import copper from "../../images/foilimg/copper.jpg";
//foil images
import gold from "../../images/foilimg/gold.jpg";
import green from "../../images/foilimg/green.jpg";
import orange from "../../images/foilimg/orange.jpg";
import pink from "../../images/foilimg/pink.jpg";
import red from "../../images/foilimg/red.jpg";
import silver from "../../images/foilimg/silver.jpg";
import arrowleft from "../../images/icon/arrowleft.svg";
import arrowright from "../../images/icon/arrowright.svg";
import closechoose from "../../images/icon/close.svg";
//paper images
import blackGloss from "../../images/paperimg/blackG.jpg";
import blackMatte from "../../images/paperimg/blackM.jpg";
import blackSatin from "../../images/paperimg/blackS.jpg";
import blackTextured from "../../images/paperimg/blackT.jpg";
import recycled from "../../images/paperimg/recycled.jpg";
import whiteGloss from "../../images/paperimg/whiteG.jpg";
import whiteMatte from "../../images/paperimg/whiteM.jpg";
import whiteSatin from "../../images/paperimg/whiteS.jpg";
import whiteTextured from "../../images/paperimg/whiteT.jpg";
import metal from "../../images/paperimg/metal.png";
import metalBrush from "../../images/paperimg/metalBrush.png";
import { paperPicker } from "./paper";
import styles from "./styles/Bottom3D.module.scss";

const Bottom3D = ({
  activeLayer,
  colorSelectedFoils,
  selectedPaper,
  setColorSelectedFoils,
  setSelectedPaper,
  isVisibleFoilVar,
  setIsVisibleFoilVar,
  setActiveLayer,
  isVisibleLeft,
}: {
  activeLayer: string;
  colorSelectedFoils: FoilMapping;
  selectedPaper: PaperMaterial;
  setColorSelectedFoils: React.Dispatch<React.SetStateAction<FoilMapping>>;
  setSelectedPaper: React.Dispatch<React.SetStateAction<PaperMaterial>>;
  isVisibleFoilVar: Boolean | undefined;
  setIsVisibleFoilVar: React.Dispatch<React.SetStateAction<Boolean>>;
  setActiveLayer: React.Dispatch<React.SetStateAction<string | undefined>>;
  isVisibleLeft: Boolean | undefined;
}) => {
  const scrollDivRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  const arrowScroll = useCallback(
    (scrollValue: number) => {
      scrollDivRef.current?.scrollBy({ top: scrollValue, behavior: "smooth" });
    },
    [scrollDivRef]
  );

  return (
    <div
      className={classNames(
        styles.bottom,
        styles.choixselectionne,
        isVisibleFoilVar ? "" : styles.clickchoose,
        !isVisibleLeft ? "" : styles.clickLeft,
        "col-12"
      )}
    >
      <div className={classNames(styles.container, "container row")}>
        <div className={classNames(styles.choosetitle, "row")}>
          <p>{t(activeLayer)}</p>
          <img
            onClick={(e) => {
              setIsVisibleFoilVar(false);
              setActiveLayer(undefined);
            }}
            src={closechoose}
            alt="Close choose foil and paper"
          />
        </div>
        <div className={classNames("col-2")}></div>
        <div className={classNames(styles.content, "col-9 row")}>
          <div
            className={classNames(styles.arrowleft)}
            onClick={() => {
              arrowScroll(-500);
            }}
          >
            <img src={arrowleft} alt="Show more foil to left" />
          </div>
          <div
            className={classNames(styles.arrowright)}
            onClick={() => arrowScroll(500)}
          >
            <img src={arrowright} alt="Show more foil to right" />
          </div>
          <div className={classNames(styles.position)}>
            <div
              className={classNames(styles.scroll, "row")}
              ref={scrollDivRef}
            >
              {activeLayer === "paper"
                ? Object.keys(paperPicker).map((paperName) => {
                    return (
                      <div
                        key={paperName}
                        className={classNames(
                          selectedPaper.name === paperName &&
                            styles.selectedfoil,
                          styles.choosefoil,
                          styles.paperselect,
                          "row"
                        )}
                        onClick={() => {
                          setSelectedPaper(paperPicker[paperName]);
                        }}
                      >
                        <div
                          className={classNames(
                            paperName.includes("White")
                              ? styles.blackfoilzone
                              : styles.foilzone,
                            "col-10"
                          )}
                        >
                          <img
                            className={classNames(styles.paperimg, "col-12")}
                            src={mapPaperNameToPaperImg(paperName)}
                            alt={paperName}
                          />
                        </div>
                        <p className={classNames(styles.papertext, "col-12")}>
                          {t(paperName)}
                        </p>
                      </div>
                    );
                  })
                : Object.keys(DEFAULT_FOIL_MAPPING).map((foilName) => {
                    return (
                      <div
                        key={foilName}
                        className={classNames(
                          displaySelectedFoil(
                            activeLayer,
                            foilName,
                            colorSelectedFoils
                          ),
                          styles.choosefoil,
                          "row"
                        )}
                        onClick={() => {
                          setColorSelectedFoils({
                            ...DEFAULT_FOIL_MAPPING,
                            [activeLayer]:
                              DEFAULT_FOIL_MAPPING[foilName as FoilColorName],
                          });
                        }}
                      >
                        <div className={classNames(styles.foilzone, "col-10")}>
                          <img
                            className={classNames("col-12")}
                            src={mapFoilNameToFoilImg(foilName)}
                            alt={foilName}
                          />
                        </div>
                        <p className={classNames(styles.foiltext, "col-12")}>
                          {t(foilName)}
                        </p>
                      </div>
                    );
                  })}
            </div>
          </div>
        </div>
        <div className={classNames(styles.close, "col-2")}></div>
      </div>
    </div>
  );
};

export default Bottom3D;

export function mapFoilNameToFoilImg(foilName: string): string | undefined {
  switch (foilName) {
    case "goldFoil":
      return gold;
    case "silverFoil":
      return silver;
    case "copperFoil":
      return copper;
    case "redFoil":
      return red;
    case "greenFoil":
      return green;
    case "blueFoil":
      return blue;
    case "orangeFoil":
      return orange;
    case "pinkFoil":
      return pink;
    case "blackFoil":
      return black;
    default:
      return undefined;
  }
}

export function mapPaperNameToPaperImg(paperName: string): string | undefined {
  switch (paperName) {
    case "BlackGloss":
      return blackGloss;
    case "BlackMatte":
      return blackMatte;
    case "BlackSatin":
      return blackSatin;
    case "BlackTextured":
      return blackTextured;
    case "RecycledPaper":
      return recycled;
    case "WhiteGloss":
      return whiteGloss;
    case "WhiteMatte":
      return whiteMatte;
    case "WhiteSatin":
      return whiteSatin;
    case "WhiteTextured":
      return whiteTextured;
    case "Metallic":
      return metal;
    case "BrushedMetallic":
      return metalBrush;
    default:
      return undefined;
  }
}

export function displaySelectedFoil(
  activeLayer: string,
  foilName: string,
  colorSelectedFoils: FoilMapping
): string {
  if (activeLayer === "goldFoil") {
    if (colorSelectedFoils.goldFoil.name === foilName)
      return styles.selectedfoil;
  }
  if (activeLayer === "silverFoil") {
    if (colorSelectedFoils.silverFoil.name === foilName)
      return styles.selectedfoil;
  }
  if (activeLayer === "copperFoild") {
    if (colorSelectedFoils.copperFoil.name === foilName)
      return styles.selectedfoil;
  }
  if (activeLayer === "redFoil") {
    if (colorSelectedFoils.redFoil.name === foilName)
      return styles.selectedfoil;
  }
  if (activeLayer === "greenFoil") {
    if (colorSelectedFoils.greenFoil.name === foilName)
      return styles.selectedfoil;
  }
  if (activeLayer === "blueFoil") {
    if (colorSelectedFoils.blueFoil.name === foilName)
      return styles.selectedfoil;
  }
  if (activeLayer === "pinkFoil") {
    if (colorSelectedFoils.pinkFoil.name === foilName)
      return styles.selectedfoil;
  }
  if (activeLayer === "orangeFoil") {
    if (colorSelectedFoils.orangeFoil.name === foilName)
      return styles.selectedfoil;
  }
  if (activeLayer === "blackFoil") {
    if (colorSelectedFoils.blackFoil.name === foilName)
      return styles.selectedfoil;
  }
  return "";
}
