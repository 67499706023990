import classNames from "classnames";
import React from "react";
import styles from "./styles/Right3D.module.scss";
import facebook from "../../images/icon/facebook.svg";
import linkedin from "../../images/icon/linkedin.svg";
import facebookw from "../../images/icon/facebookw.svg";
import linkedinw from "../../images/icon/linkedinw.svg";
import twitterw from "../../images/icon/twitterw.svg";
import twitter from "../../images/icon/twitter.svg";
import filtrer from "../../images/icon/close.svg";
import color from "../../images/icon/colors.svg";
import colorw from "../../images/icon/colorsw.svg";
import colorb from "../../images/icon/colorsb.svg";
import { useHistory, useLocation } from "react-router-dom";
// import { useTranslation } from "react-i18next";
import { Book } from "../../Domain/book";
import { ProductContent } from "../../repositories/ProductRepository";
import Plierdeplier from "./Plierdeplier";

const Right3D = ({
  backgroundColor,
  setBackgroundColor,
  // client,
  // handleToggleVisibleDevis,
  isVisibleDevis,
  book,
  folded,
  setFolded,
  packaging,
  active360Background,
  setActive360Background,
}: {
  backgroundColor: string;
  setBackgroundColor: React.Dispatch<string>;
  // client: string;
  // handleToggleVisibleDevis: (
  //   event: React.SyntheticEvent<Element, Event> | React.KeyboardEvent<Element>
  // ) => void;
  isVisibleDevis: Boolean | undefined;
  book: Book | undefined;
  folded: boolean;
  setFolded: React.Dispatch<boolean>;
  packaging: ProductContent;
  active360Background: string | undefined;
  setActive360Background: React.Dispatch<string | undefined>;
}) => {
  // const { t } = useTranslation();
  const history = useHistory();

  const location = useLocation();

  const isDemo = location.hash.includes("#demo");

  return (
    <div
      className={classNames(
        styles.allpage,
        isVisibleDevis === undefined
          ? ""
          : isVisibleDevis
          ? styles.devinotvisible
          : styles.divisvisible,
        "col-12"
      )}
    >
      <div className={classNames(styles.container)}>
        <div className={classNames(styles.content, "col-12 row")}>
          {!isDemo && (
            <div
              className={classNames(styles.filterzone, "col-12 row")}
              onClick={() => history.goBack()}
            >
              <div
                className={classNames(
                  styles.close,
                  backgroundColors.black === backgroundColor
                    ? styles.invert
                    : ""
                )}
              >
                <img
                  className={classNames(styles.closeimg)}
                  src={filtrer}
                  alt="Filtrer"
                />
              </div>
            </div>
          )}
          <div className={classNames(styles.socialzone, "col-12 row")}>
            <div className={classNames(styles.social, "col-12 row")}>
              <div
                className={classNames(
                  backgroundColors.black === backgroundColor
                    ? styles.iconw
                    : styles.icon,
                  "col-12"
                )}
              >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={book?.share?.find((s) => s.name === "facebook")?.url}
                >
                  <div data-title={"Partage Facebook"}>
                    <img
                      src={
                        backgroundColors.black === backgroundColor
                          ? facebookw
                          : facebook
                      }
                      alt="Facebook page"
                    />
                  </div>
                </a>
              </div>
              <div
                className={classNames(
                  backgroundColors.black === backgroundColor
                    ? styles.iconw
                    : styles.icon,
                  "col-12"
                )}
              >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={book?.share?.find((s) => s.name === "linkedin")?.url}
                >
                  <div data-title={"Partage Linkedin"}>
                    <img
                      src={
                        backgroundColors.black === backgroundColor
                          ? linkedinw
                          : linkedin
                      }
                      alt="Linkedin page"
                    />
                  </div>
                </a>
              </div>

              <div
                className={classNames(
                  backgroundColors.black === backgroundColor
                    ? styles.iconw
                    : styles.icon,
                  "col-12"
                )}
              >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={book?.share?.find((s) => s.name === "twitter")?.url}
                >
                  <div data-title={"Partage Linkedin"}>
                    <img
                      src={
                        backgroundColors.black === backgroundColor
                          ? twitterw
                          : twitter
                      }
                      alt="Linkedin page"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className={classNames(styles.colorzone, "col-12 row")}>
            <div className={classNames(styles.color)}>
              <img
                className={classNames(styles.colorimg)}
                src={
                  active360Background
                    ? colorb
                    : backgroundColors.black === backgroundColor
                    ? colorw
                    : color
                }
                alt="Change color background"
              />
              <div className={classNames(styles.choosecolor)}>
                {Object.keys(backgroundColors).map((background, i) => {
                  return (
                    <div key={i} className={classNames(styles.onecolor)}>
                      {mapBackgroundNameToGradient(background) ===
                        backgroundColor && (
                        <div className={classNames(styles.selected)}></div>
                      )}
                      <div
                        className={classNames(
                          styles[background],
                          styles.scale,
                          mapBackgroundNameToGradient(background) ===
                            backgroundColor && styles.selected
                        )}
                        onClick={() => {
                          setBackgroundColor(
                            mapBackgroundNameToGradient(background)
                          );
                          setActive360Background(undefined);
                        }}
                      ></div>
                    </div>
                  );
                })}
                {Object.keys(envMapPicker).map((envmap, i) => {
                  return (
                    <div key={i} className={classNames(styles.onecolor)}>
                      {envMapPicker[envmap] === active360Background && (
                        <div className={classNames(styles.selected)}></div>
                      )}
                      <div
                        style={{
                          backgroundImage: `url(${process.env.PUBLIC_URL}/envMap/${envMapPicker[envmap]}.jpg)`,
                          backgroundSize: "contain",
                        }}
                        className={classNames(
                          styles[envmap],
                          styles.scale,
                          envMapPicker[envmap] === active360Background &&
                            styles.selected
                        )}
                        onClick={() => {
                          setActive360Background(envMapPicker[envmap]);
                          setBackgroundColor("");
                        }}
                      ></div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {packaging.type === "custom" && (
            <Plierdeplier folded={folded} setFolded={setFolded} />
          )}
          <div
            className={classNames(
              styles.contactzone,
              backgroundColors.black === backgroundColor ? styles.black : "",
              backgroundColors.grey === backgroundColor ? styles.gris : "",
              backgroundColors.white === backgroundColor ? styles.white : "",
              "col-12 row"
            )}
          >
            {/* <div
              onClick={(e) => {
                isDemo
                  ? window
                      .open("https://mgi-fr.com/mgi-connect/", "_blank")
                      ?.focus()
                  : handleToggleVisibleDevis(e);
              }}
              className={classNames(isDemo ? styles.learnMore : styles.contact)}
            >
              <p className={classNames(isDemo ? styles.sub : styles.contactus)}>
                {t(isDemo ? "Subscribe" : "Devis")}
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Right3D;

export const backgroundColors = {
  white: "radial-gradient(circle at 50% 60%, #FAFAFA, #FCFCFC)",
  grey: "radial-gradient(rgb(236 236 236), rgb(208 208 208))",
  black: "radial-gradient(circle at 50% 60%, #323232, #090909)",
};

function mapBackgroundNameToGradient(backgroundColor: string) {
  switch (backgroundColor) {
    case "black":
      return backgroundColors.black;
    case "grey":
      return backgroundColors.grey;
    case "white":
      return backgroundColors.white;
    default:
      return backgroundColors.grey;
  }
}

interface ChooseEnvMap {
  [envMapName: string]: string;
}

export const envMapPicker: ChooseEnvMap = {
  studio: "colorful_studio",
  outdoor1: "outdoor_1",
  outdoor2: "outdoor_2",
  outdoor4: "outdoor_4",
  outdoor3: "indoor",
  indoor2: "indoor2",
  vignes: "vignes",
};
