import { useEffect, useState } from "react";
import { useBackend } from "../backend/BackendProvider";
import { Book, BookRepository } from "../Domain/book";

export function UseBook(client: string): Book | undefined {
  const [book, setBook] = useState<Book>();
  const backend = useBackend();
  useEffect(() => {
    async function fetchData() {
      const bk = await BookRepository(client, backend);
      bk && setBook(bk);
    }
    fetchData();
  }, [backend, client, setBook]);
  return book;
}
