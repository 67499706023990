import { PaperMaterial } from "@mgi-labs/preview";

interface ChoosePaper {
  [paperName: string]: PaperMaterial;
}

export const paperPicker: ChoosePaper = {
  RecycledPaper: {
    name: "RecycledPaper",
    envMapIntensity: 0,
    normalScaleFactor: 0.01,
    vecNormalScale: 4.0,
    roughness: 1,
  },
  WhiteGloss: {
    name: "WhiteGloss",
    envMapIntensity: 0.25,
    normalScaleFactor: 0.01,
    vecNormalScale: 4.0,
    roughness: 0,
  },
  BlackGloss: {
    name: "BlackGloss",
    envMapIntensity: 0.25,
    normalScaleFactor: 0.01,
    vecNormalScale: 4.0,
    roughness: 0,
  },
  WhiteMatte: {
    name: "WhiteMatte",
    envMapIntensity: 0,
    normalScaleFactor: 0.01,
    vecNormalScale: 4.0,
    roughness: 1,
  },
  BlackMatte: {
    name: "BlackMatte",
    envMapIntensity: 0,
    normalScaleFactor: 0.01,
    vecNormalScale: 4.0,
    roughness: 1,
  },
  WhiteTextured: {
    name: "WhiteTextured",
    envMapIntensity: 0.15,
    normalScaleFactor: 100,
    vecNormalScale: 0.25,
    roughness: 0.5,
  },
  BlackTextured: {
    name: "BlackTextured",
    envMapIntensity: 0.15,
    normalScaleFactor: 100,
    vecNormalScale: 0.25,
    roughness: 0.5,
  },
  WhiteSatin: {
    name: "WhiteSatin",
    envMapIntensity: 0.3,
    normalScaleFactor: 0.05,
    vecNormalScale: 25.0,
    roughness: 0.85,
  },
  BlackSatin: {
    name: "BlackSatin",
    envMapIntensity: 0.3,
    normalScaleFactor: 0.05,
    vecNormalScale: 25.0,
    roughness: 0.85,
  },
  Metallic: {
    name: "Metallic",
    envMapIntensity: 0.8,
    normalScaleFactor: 0.0,
    vecNormalScale: 0.0,
    roughness: 0.3,
    metalness: 0.7,
  },
  BrushedMetallic: {
    name: "BrushedMetallic",
    envMapIntensity: 0.6,
    normalScaleFactor: 0.015,
    vecNormalScale: 0.0,
    roughness: 0.29,
    metalness: 0.15,
    diffuse: "#c6cfd7",
  },
};
