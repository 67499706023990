import React, { useRef, useState } from "react";
import classNames from "classnames";
import styles from "./styles/Right.module.scss";
import sharelink from "../../images/icon/sharelinkb.svg";
import sharelinkw from "../../images/icon/sharelinkw.svg";
import { backgroundColors } from "./right3D";

const CopyLink = ({ backgroundColor }: { backgroundColor?: String }) => {
  const [titleText, setTitleText] = useState<string>("Copier le lien");

  const texteAreaRef = useRef<HTMLTextAreaElement>(null);

  const copyCodeToClipboard = () => {
    navigator.clipboard.writeText(window.location.href);
  };

  return (
    <>
      <textarea
        className={classNames(styles.displaynone, styles.centered, "col-12")}
        ref={texteAreaRef}
        readOnly
        value="https://virtual-book.osc-fr1.scalingo.io/"
      />
      <div data-title={titleText}>
        <img
          className={classNames(styles.arrow, styles.datatitle)}
          onClick={() => {
            copyCodeToClipboard();
            setTitleText("Lien Copié");
          }}
          src={
            backgroundColors.black === backgroundColor ? sharelinkw : sharelink
          }
          alt="Copier le lien"

          //title={"Copier le lien"}
          // data-title = {titleText}
          //styles.datatitle
        />
      </div>
    </>
  );
};
export default CopyLink;
