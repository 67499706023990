import classNames from "classnames";
import React from "react";

import { Book } from "../../Domain/book";
import Logomgikm from "../../images/Logo/Logomgikm.svg";
import styles from "./styles/Left.module.scss";
import { isharrisbruno } from "../../checkharrisbruno";
import Logohb from "../../images/Logo/logohb.svg";
const Left = ({
  selectedProjectType,
  scrollToProjectType,
  client,
  book,
}: {
  selectedProjectType: number;
  client: string;
  scrollToProjectType: (activeProjectType: number) => void;
  book: Book;
}) => {
  return (
    <div className={classNames(styles.allpage, "col-12")}>
      <div className={classNames(styles.container)}>
        <div className={classNames(styles.content, "col-12 row")}>
          <div className={classNames(styles.infoentreprise, "col-12 row")}>
            <div className={classNames(styles.logoentreprise, "col-12")}>
              <img
                src={
                  book.logoUrl
                    ? book.logoUrl
                    : isharrisbruno()
                    ? "/logo.svg"
                    : "/client/" + client + "/logo.svg"
                }
                alt="Logo small catalogue"
              />
            </div>
          </div>

          {/*
          Mis en commentaire pour le moment, et on supprimera cette partie quand Edmond aura validé
          (En gros c'est si jamais Edmond dis que c'était mieux avant)
          <div className={classNames(styles.titlezone, "col-12 row")}>
            <div className={classNames(styles.nameentreprise, "col-12")}>
              <p>MGI DIGITAL TECHNOLOGY 2020 - 2021</p>
            </div>
          </div> */}
          <div className={classNames(styles.choose, "col-12 row")}>
            <div className={classNames(styles.choosetype, "col-12 row")}>
              {book.category?.map((category) => (
                <span
                  key={category.id}
                  className={classNames(styles.type, "col-12")}
                  onClick={() => {
                    scrollToProjectType(category.id);
                  }}
                >
                  <h2
                    className={classNames(
                      selectedProjectType === category.id && styles.selected
                    )}
                  >
                    {category.name === ""
                      ? "Page " + (category.id + 1)
                      : category.name}
                  </h2>
                </span>
              ))}
            </div>
            {/* Version mobile */}

            <div className={classNames(styles.choosetypemobile, "col-12 row")}>
              {book.category?.map((category) => (
                <span
                  key={category.id}
                  onClick={() => {
                    scrollToProjectType(category.id);
                  }}
                  className={classNames(styles.type, "col-12")}
                >
                  <h2
                    className={classNames(
                      selectedProjectType === category.id && styles.selected
                    )}
                  >
                    {category.name === ""
                      ? "Page " + (category.id + 1)
                      : category.name}
                  </h2>
                </span>
              ))}
            </div>
          </div>
          <div className={classNames(styles.contactzone, "col-12 row")}>
            <div className={classNames(styles.contact)}>
              <a className={classNames(styles.contactus)} href="./">
                {!isharrisbruno() ? (
                  <img src={Logomgikm} alt="Logomgikm" />
                ) : (
                  <img
                    className={classNames(styles.hblogo)}
                    src={Logohb}
                    alt="Logohb"
                  />
                )}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Left;
