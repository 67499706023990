import { Flat } from "@mgi-labs/preview";

const goldenWoodFlat: Flat = new Flat({
  name: "GoldenWood",
  paper: {
    layer: { url: "BlackSatin.png", name: "paper" },
  },
  design: {
    recto: {
      color: {
        layer: {
          url: "color.png",
          url_SD: "color.png",
          name: "color",
          width: 2048,
          height: 1305,
        },
      },
      goldFoil: {
        layer: {
          url: "goldFoil.png",
          url_SD: "goldFoil.png",
          name: "Foil Gold",
        },
      },
      varnish: {
        layer: { url: "varnish.png", url_SD: "varnish.png", name: "Varnish" },
      },
    },
    verso: {
      color: {
        layer: {
          url: "color2.png",
          url_SD: "color2.png",
          name: "color",
          width: 2048,
          height: 1305,
        },
      },
      goldFoil: {
        layer: {
          url: "goldFoil2.png",
          url_SD: "goldFoil2.png",
          name: "Foil Gold",
        },
      },
      varnish: {
        layer: { url: "varnish2.png", url_SD: "varnish2.png", name: "Varnish" },
      },
    },
  },
});

export default goldenWoodFlat;
