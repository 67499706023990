import classNames from "classnames";
import React from "react";
import deplier from "../../images/icon/Plierdeplier/deplier.svg";
import plier from "../../images/icon/Plierdeplier/plier.svg";
import styles from "./styles/Plierdeplier.module.scss";

export default function Plierdeplier({
  folded,
  setFolded,
}: {
  folded: boolean;
  setFolded: React.Dispatch<boolean>;
}) {
  return (
    <div className={classNames(styles.plierdeplier, "row")}>
      <div
        className={classNames(styles.button, !folded && styles.selectedbtn)}
        onClick={() => setFolded(false)}
      >
        <img src={deplier} alt="Deplier le packaging" />
      </div>
      <div
        className={classNames(styles.button, folded && styles.selectedbtn)}
        onClick={() => setFolded(true)}
      >
        <img src={plier} alt="Plier le packaging" />
      </div>
      <div
        className={classNames(
          styles.selected,
          folded ? styles.plierselected : styles.deplierselected
        )}
      ></div>
    </div>
  );
}
