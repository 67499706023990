export const translate = {
  en: {
    translation: {
      //Type of project
      bottleLabel: "Label",
      flatSupport: "Flat Support",

      //Filter
      Filter: "Share",

      //Contact
      Contact: "Contact us",

      Subscribe: "Learn more",

      //Devis
      Devis: "Contact us",

      Devistitle: "Contact us",
      Yourinfo: "Your information",
      Entreprise: "Company",
      Email: "Email",
      Phone: "Phone",

      Nbcopies: "Number of copies",

      Recap: "Summary",

      Type: "Type",
      Paper: "Paper",

      //Layers
      color: "Color",
      varnish: "Varnish",

      //Foils
      goldFoil: "Gold",
      silverFoil: "Silver",
      copperFoil: "Copper",
      redFoil: "Red",
      greenFoil: "Green",
      blueFoil: "Blue",
      pinkFoil: "Pink",
      orangeFoil: "Orange",
      blackFoil: "Black",

      //Papers
      WhiteMatte: "White Matte",
      BlackMatte: "Black Matte",
      WhiteGloss: "White Glossy",
      BlackGloss: "Black Glossy",
      WhiteTextured: "White Textured",
      BlackTextured: "Black Textured",
      WhiteSatin: "White Satin",
      BlackSatin: "Black Satin",
      RecycledPaper: "Recycled",

      contactForm: {
        send: "Send",
        cancel: "Cancel",
        close: "Close",
      },
    },
  },
  fr: {
    translation: {
      //Type of project
      bottleLabel: "Etiquette",
      flatSupport: "Support à plat",

      //Filter
      Filter: "Partager",

      //Contact
      Contact: "Nous contacter",

      Subscribe: "En savoir plus",

      //Devis
      Devis: "Nous contacter",

      Devistitle: "Nous contacter",
      Yourinfo: "Vos informations",
      Entreprise: "Entreprise",
      Email: "Email",
      Phone: "Portable",

      Nbcopies: "Nombre de copies",

      Recap: "Récapitulatif",

      Type: "Type",
      Paper: "Papier",

      //Layers
      color: "Couleur",
      varnish: "Vernis",

      //Foils
      goldFoil: "Or",
      silverFoil: "Argent",
      copperFoil: "Cuivre",
      redFoil: "Rouge",
      greenFoil: "Vert",
      blueFoil: "Bleu",
      pinkFoil: "Rose",
      orangeFoil: "Orange",
      blackFoil: "Noir",

      //Papers
      WhiteMatte: "Blanc Mat",
      BlackMatte: "Noir Mat",
      WhiteGloss: "Blanc Brillant",
      BlackGloss: "Noir Brillant",
      WhiteTextured: "Blanc Texturé",
      BlackTextured: "Noir Texturé",
      WhiteSatin: "Blanc Satin",
      BlackSatin: "Noir Satin",
      RecycledPaper: "Recyclé",

      contactForm: {
        send: "Envoyer",
        cancel: "Annuler",
        close: "Fermer",
      },
    },
  },
};
