import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { BackendProvider } from "./backend/BackendProvider";
import Backend from "./components/BackendClient";
import ChoixProjet from "./components/ChoixProjet";
import { PreviewSceneContainer } from "./components/ChoixProjet/previewScene";

import * as Sentry from "@sentry/react";

function App({ backendUrl }: { backendUrl: string }) {
  return (
    <BackendProvider value={new Backend(backendUrl)}>
      <BrowserRouter>
        <Switch>
          <Route path="*/creation/:projectId/:projectName">
            <PreviewSceneContainer />
          </Route>
          <Redirect exact from="/" to="/demo" />
          <Route path="/:clientName/:category?">
            <ChoixProjet />
          </Route>
        </Switch>
      </BrowserRouter>
    </BackendProvider>
  );
}

export default Sentry.withProfiler(App);
