import {
  Coords3D,
  CreationState,
  FoilMapping,
  PaperMaterial,
  PreviewScene,
} from "@mgi-labs/preview";
import { mapBottleNameToBottleObject } from "@mgi-labs/preview/dist/Domain/Bottle";
import classNames from "classnames";
import React, { useRef } from "react";
import { Canvas } from "@react-three/fiber";
import { Object3D } from "three";
import { BookBottleName } from "../Domain/bottle";
import styles from "./ChoixProjet/styles/Preview3D.module.scss";

const DEFAULT_CAMERA_POSITION: Coords3D = [-4, 4, 8];

export function Preview3D({
  previewState,
  colorSelectedFoils,
  selectedPaper,
  backgroundColor,
  isVisibleDevis,
  showOnBottle,
  selectedBottle,
  labelPosition,
  folded,
  active360Background,
}: {
  previewState: CreationState;
  colorSelectedFoils: FoilMapping;
  selectedPaper: PaperMaterial;
  backgroundColor: string;
  isVisibleDevis: Boolean | undefined;
  showOnBottle: boolean;
  selectedBottle: BookBottleName;
  labelPosition: number | undefined;
  folded: boolean;
  active360Background: string | undefined;
}) {
  const scene = useRef<Object3D>(null);

  return (
    <div
      className={classNames(
        styles.prev,
        isVisibleDevis ? styles.divisvisible : styles.devinotvisible
      )}
    >
      <Canvas
        style={{
          background: backgroundColor,
        }}
        camera={{ position: DEFAULT_CAMERA_POSITION }}
      >
        <PreviewScene
          forwardedRef={scene}
          creationState={previewState}
          opacity={1}
          foilMapping={colorSelectedFoils}
          activePaper={selectedPaper}
          showOnBottle={showOnBottle}
          selectedBottle={mapBottleNameToBottleObject(selectedBottle)}
          labelPosition={labelPosition}
          folded={folded}
          active360Background={active360Background}
        />
      </Canvas>
    </div>
  );
}
