import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Book } from "../../Domain/book";
import facebook from "../../images/icon/facebook.svg";
import linkedin from "../../images/icon/linkedin.svg";
import share from "../../images/icon/share.svg";
import twitter from "../../images/icon/twitter.svg";
import Copylink from "./copylink";
import styles from "./styles/Right.module.scss";

const Right = ({ book }: { book: Book }) => {
  const { t } = useTranslation();
  const facebookLink = book?.share?.find((s) => s.name === "facebook")?.url;
  const linkedinLink = book?.share?.find((s) => s.name === "linkedin")?.url;
  const twitterLink = book?.share?.find((s) => s.name === "twitter")?.url;

  return (
    <div className={classNames(styles.allpage, "col-12")}>
      <div className={classNames(styles.container)}>
        <div className={classNames(styles.content, "col-12 row")}>
          <div className={classNames(styles.filterzone, "col-12 row")}>
            <div className={classNames(styles.filter)}>
              <img
                className={classNames(styles.filterimg)}
                src={share}
                alt="Share"
              />
              <p className={classNames(styles.text)}>{t("Filter")}</p>
              <Copylink />
            </div>
          </div>
          <div className={classNames(styles.socialzone, "col-12 row")}>
            <div className={classNames(styles.social, "col-12 row")}>
              {facebookLink && (
                <div className={classNames(styles.icon, "col-12 col-md-4")}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={facebookLink}
                  >
                    <div data-title={"Page Facebook"}>
                      <img src={facebook} alt="Facebook page" />
                    </div>
                  </a>
                </div>
              )}

              {linkedinLink && (
                <div className={classNames(styles.icon, "col-12 col-md-4")}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={linkedinLink}
                  >
                    <div data-title={"Page Linkedin"}>
                      <img src={linkedin} alt="Linkedin page" />
                    </div>
                  </a>
                </div>
              )}
              {twitterLink && (
                <div className={classNames(styles.icon, "col-12 col-md-4")}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={twitterLink}
                  >
                    <div data-title={"Page twitter"}>
                      <img src={twitter} alt="Twitter page" />
                    </div>
                  </a>
                </div>
              )}
            </div>
          </div>
          <div className={classNames(styles.contactzone, "col-12 row")}>
            {/* <div className={classNames(styles.contact)}>
              <p className={classNames(styles.contactus)}>{t("Contact")}</p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Right;
