import { AutoFold, CustomizedProduct, Flat } from "@mgi-labs/preview";
import Backend from "../components/BackendClient";
import pChateauFresnes from "../images/catalogue/1.jpg";
import goldenWood from "../images/catalogue/11.jpg";
import imagePlus from "../images/catalogue/12.jpg";
import pmediflex from "../images/catalogue/3.jpg";
import pWhiskyDeer from "../images/catalogue/5.jpg";
import fcompany from "../images/catalogue/9.jpg";
import fbird from "../images/catalogue/flat/bird.jpg";
import fcoiffeur from "../images/catalogue/flat/coiffeur.jpg";
import fdragon from "../images/catalogue/flat/dragon.jpg";
import fdumus from "../images/catalogue/flat/dumus.jpg";
import etacCerfThumbnail from "../images/catalogue/flat/etacCerfThumbnail.jpg";
import etacLionThumbnail from "../images/catalogue/flat/etacLionThumbnail.jpg";
import etacOngleThumbnail from "../images/catalogue/flat/etacOngleThumbnail.jpg";
import fexaprintcard from "../images/catalogue/flat/flat_card_exaprint.jpg";
import fexaprintlabel from "../images/catalogue/flat/flat_label-exaprint1.jpg";
import fexaprintmenu from "../images/catalogue/flat/flat_menu_exaprint.jpg";
import fflower from "../images/catalogue/flat/flower.jpg";
import fmagnolia from "../images/catalogue/flat/flowerposter.jpg";
import lbeer from "../images/catalogue/label/beer.jpg";
import lbeeranimal from "../images/catalogue/label/beeranimal.jpg";
import lcaviar from "../images/catalogue/label/caviar.jpg";
import lexplore from "../images/catalogue/label/explore.jpg";
import lvodka from "../images/catalogue/label/lvodka.jpg";
import lwhitebeer from "../images/catalogue/label/whitebeer.jpg";
import lwineblue from "../images/catalogue/label/wineblue.jpg";
import lwinegrape from "../images/catalogue/label/winegrape.jpg";
import pcasque from "../images/catalogue/packaging/casque.jpg";
import pchampagne from "../images/catalogue/packaging/champagne.jpg";
import pmedard from "../images/catalogue/packaging/medard.jpg";

import boulangerieFlat from "../products/boulangerie";
import casque from "../products/casque";
import caviarFlat from "../products/caviarFlat";
import celestialFlat from "../products/celestialFlat";
import champagne from "../products/champagne";
import chateauDeFresnesFlat from "../products/chateauDeFresnesFlat";
import companyFlat from "../products/companyFlat";
import domusFlat from "../products/domusFlat";
import dragonFlat from "../products/dragonFlat";
import etacCerf from "../products/etacCerf";
import etacLion from "../products/etacLion";
import etacOngle from "../products/etacOngle";
import explorerFlat from "../products/explorerFlat";
import floralArtFlat from "../products/floralArtFlat";
import goldenWoodFlat from "../products/goldenWoodFlat";
import grappeFlat from "../products/grappeFlat";
import hairStyleFlat from "../products/hairStyleFlat";
import imagePlusFlat from "../products/imagePlusFlat";
import kiwanoFlat from "../products/kiwanoFlat";
import lagerBeerFlat from "../products/lagerBeerFlat";
import magnoliaFlat from "../products/magnoliaFlat";
import medardBox from "../products/medardBox";
import { mediflex } from "../products/mediflex";
import saintJulienFlat from "../products/saintJulienFlat";
import smileFlat from "../products/smileFlat";
import victoryFlat from "../products/victory";
import vodkaFlat from "../products/vodkaFlat";
import deerWhisky from "../products/whiskyDeer";
import whiteBloomFlat from "../products/whiteBloomFlat";

export type Category = {
  id: number;
  name: string;
  gridSize: number;
  projects?: { id: number; spot: number }[];
};

export type ShareLink = {
  name: string;
  url: string;
};

export type Book = {
  id: string;
  name: string;
  static: boolean;
  primaryColor: string;
  secondaryColor: string;
  url?: string;
  share?: ShareLink[];
  category: Category[];
  logoUrl?: string;
  projects?: Asset[];
};

export type Asset = {
  id: number;
  categoryId: number;
  product: CustomizedProduct | Flat;
  thumbnailColor?: string;
  thumbnailForBook?: string;
  name?: string;
  type?: string;
  autoFold?: AutoFold;
};

export const defaultBook: Book = {
  id: "1",
  name: "Etac",
  static: true,
  primaryColor: "#5286a2",
  secondaryColor: "#5286a2",
  url: "etac",
  category: [
    {
      id: 1,
      name: "Packaging",
      projects: [
        { id: 0, spot: 0 },
        { id: 1, spot: 1 },
        { id: 2, spot: 2 },
        { id: 3, spot: 3 },
        { id: 4, spot: 4 },
      ],
      gridSize: 5,
    },

    {
      id: 2,
      name: "Label",
      projects: [
        { id: 5, spot: 0 },
        { id: 6, spot: 1 },
        { id: 7, spot: 2 },
        { id: 8, spot: 3 },
        { id: 9, spot: 4 },
        { id: 10, spot: 5 },
        { id: 11, spot: 6 },
        { id: 12, spot: 7 },
        { id: 13, spot: 8 },
      ],
      gridSize: 9,
    },
    {
      id: 4,
      name: "Flat Support",
      projects: [
        { id: 14, spot: 0 },
        { id: 15, spot: 1 },
        { id: 16, spot: 2 },
        { id: 17, spot: 3 },
        { id: 18, spot: 4 },
        { id: 19, spot: 5 },
        { id: 20, spot: 6 },
        { id: 21, spot: 7 },
        { id: 22, spot: 8 },
      ],
      gridSize: 9,
    },
  ],
  projects: [
    {
      categoryId: 1,
      type: "flat" as const,
      product: medardBox,
      thumbnailColor: pmedard,
    },

    {
      categoryId: 1,
      type: "flat" as const,
      product: mediflex,
      thumbnailColor: pmediflex,
    },
    {
      categoryId: 1,
      type: "flat" as const,
      product: champagne,
      thumbnailColor: pchampagne,
    },

    {
      categoryId: 1,
      type: "flat" as const,
      product: deerWhisky,
      thumbnailColor: pWhiskyDeer,
    },

    {
      categoryId: 1,
      type: "flat" as const,
      product: casque,
      thumbnailColor: pcasque,
    },
    {
      categoryId: 2,
      product: chateauDeFresnesFlat,
      thumbnailColor: pChateauFresnes,
    },
    {
      categoryId: 2,
      type: "flat" as const,
      product: kiwanoFlat,
      thumbnailColor: lbeeranimal,
    },
    {
      categoryId: 2,
      type: "flat" as const,
      product: caviarFlat,
      thumbnailColor: lcaviar,
    },
    {
      categoryId: 2,
      type: "flat" as const,
      product: explorerFlat,
      thumbnailColor: lexplore,
    },
    {
      categoryId: 2,
      type: "flat" as const,
      product: lagerBeerFlat,
      thumbnailColor: lbeer,
    },
    {
      categoryId: 2,
      type: "flat" as const,
      product: vodkaFlat,
      thumbnailColor: lvodka,
    },
    {
      categoryId: 2,
      type: "flat" as const,
      product: whiteBloomFlat,
      thumbnailColor: lwhitebeer,
    },
    {
      categoryId: 2,
      type: "flat" as const,
      product: saintJulienFlat,
      thumbnailColor: lwineblue,
    },
    {
      categoryId: 2,
      type: "flat" as const,
      product: grappeFlat,
      thumbnailColor: lwinegrape,
    },
    {
      categoryId: 4,
      type: "flat" as const,
      product: celestialFlat,
      thumbnailColor: fbird,
    },
    {
      categoryId: 4,
      type: "flat" as const,
      product: domusFlat,
      thumbnailColor: fdumus,
    },
    {
      categoryId: 4,
      type: "flat" as const,
      product: dragonFlat,
      thumbnailColor: fdragon,
    },
    {
      categoryId: 4,
      type: "flat" as const,
      product: floralArtFlat,
      thumbnailColor: fflower,
    },
    {
      categoryId: 4,
      type: "flat" as const,
      product: goldenWoodFlat,
      thumbnailColor: goldenWood,
    },
    {
      categoryId: 4,
      type: "flat" as const,
      product: imagePlusFlat,
      thumbnailColor: imagePlus,
    },
    {
      categoryId: 4,
      type: "flat" as const,
      product: magnoliaFlat,
      thumbnailColor: fmagnolia,
    },
    {
      categoryId: 4,
      type: "flat" as const,
      product: hairStyleFlat,
      thumbnailColor: fcoiffeur,
    },
    {
      categoryId: 4,
      type: "flat" as const,
      product: companyFlat,
      thumbnailColor: fcompany,
    },
  ].map((asset, id) => ({ ...asset, id })),
};

export const etacBook: Book = {
  id: "1",
  name: "Etac",
  static: true,
  primaryColor: "#5286a2",
  secondaryColor: "#5286a2",
  url: "etac",
  category: [
    {
      id: 1,
      name: "Packaging",
      projects: [
        { id: 0, spot: 0 },
        { id: 1, spot: 1 },
        { id: 2, spot: 2 },
        { id: 3, spot: 3 },
        { id: 4, spot: 4 },
      ],
      gridSize: 5,
    },

    {
      id: 2,
      name: "Label",
      projects: [
        { id: 5, spot: 0 },
        { id: 6, spot: 1 },
        { id: 7, spot: 2 },
        { id: 8, spot: 3 },
        { id: 9, spot: 4 },
        { id: 10, spot: 5 },
        { id: 11, spot: 6 },
        { id: 12, spot: 7 },
        { id: 13, spot: 8 },
      ],
      gridSize: 9,
    },
    {
      id: 4,
      name: "Flat Support",
      projects: [
        { id: 14, spot: 0 },
        { id: 15, spot: 1 },
        { id: 16, spot: 2 },
        { id: 17, spot: 3 },
        { id: 18, spot: 4 },
        { id: 19, spot: 5 },
        { id: 20, spot: 6 },
        { id: 21, spot: 7 },
        { id: 22, spot: 8 },
      ],
      gridSize: 9,
    },
  ],
  projects: [
    {
      categoryId: 1,
      type: "flat" as const,
      product: medardBox,
      thumbnailColor: pmedard,
    },
    {
      categoryId: 1,
      type: "flat" as const,
      product: champagne,
      thumbnailColor: pchampagne,
    },
    {
      categoryId: 1,
      type: "flat" as const,
      product: mediflex,
      thumbnailColor: pmediflex,
    },

    {
      categoryId: 1,
      type: "flat" as const,
      product: deerWhisky,
      thumbnailColor: pWhiskyDeer,
    },

    {
      categoryId: 1,
      type: "flat" as const,
      product: casque,
      thumbnailColor: pcasque,
    },
    {
      categoryId: 2,
      product: chateauDeFresnesFlat,
      thumbnailColor: pChateauFresnes,
    },
    {
      categoryId: 2,
      type: "flat" as const,
      product: kiwanoFlat,
      thumbnailColor: lbeeranimal,
    },
    {
      categoryId: 2,
      type: "flat" as const,
      product: caviarFlat,
      thumbnailColor: lcaviar,
    },
    {
      categoryId: 2,
      type: "flat" as const,
      product: explorerFlat,
      thumbnailColor: lexplore,
    },
    {
      categoryId: 2,
      type: "flat" as const,
      product: lagerBeerFlat,
      thumbnailColor: lbeer,
    },
    {
      categoryId: 2,
      type: "flat" as const,
      product: vodkaFlat,
      thumbnailColor: lvodka,
    },
    {
      categoryId: 2,
      type: "flat" as const,
      product: whiteBloomFlat,
      thumbnailColor: lwhitebeer,
    },
    {
      categoryId: 2,
      type: "flat" as const,
      product: saintJulienFlat,
      thumbnailColor: lwineblue,
    },
    {
      categoryId: 2,
      type: "flat" as const,
      product: grappeFlat,
      thumbnailColor: lwinegrape,
    },
    {
      categoryId: 3,
      type: "flat" as const,
      product: celestialFlat,
      thumbnailColor: fbird,
    },
    {
      categoryId: 3,
      type: "flat" as const,
      product: domusFlat,
      thumbnailColor: fdumus,
    },
    {
      categoryId: 3,
      type: "flat" as const,
      product: dragonFlat,
      thumbnailColor: fdragon,
    },
    {
      categoryId: 3,
      type: "flat" as const,
      product: floralArtFlat,
      thumbnailColor: fflower,
    },
    {
      categoryId: 3,
      type: "flat" as const,
      product: goldenWoodFlat,
      thumbnailColor: goldenWood,
    },
    {
      categoryId: 3,
      type: "flat" as const,
      product: imagePlusFlat,
      thumbnailColor: imagePlus,
    },
    {
      categoryId: 3,
      type: "flat" as const,
      product: etacCerf,
      thumbnailColor: etacCerfThumbnail,
    },
    {
      categoryId: 3,
      type: "flat" as const,
      product: etacLion,
      thumbnailColor: etacLionThumbnail,
    },
    {
      categoryId: 3,
      type: "flat" as const,
      product: etacOngle,
      thumbnailColor: etacOngleThumbnail,
    },
  ].map((asset, id) => ({ ...asset, id })),
};

export const exaprintBook: Book = {
  id: "1",
  name: "Exaprint",
  static: true,
  primaryColor: "#5286a2",
  secondaryColor: "#5286a2",
  url: "exaprint",
  category: [
    {
      id: 1,
      name: "Packaging",
      projects: [
        { id: 0, spot: 0 },
        { id: 1, spot: 1 },
        { id: 2, spot: 2 },
        { id: 3, spot: 3 },
        { id: 4, spot: 4 },
      ],
      gridSize: 5,
    },

    {
      id: 2,
      name: "Label",
      projects: [
        { id: 5, spot: 0 },
        { id: 6, spot: 1 },
        { id: 7, spot: 2 },
        { id: 8, spot: 3 },
        { id: 9, spot: 4 },
        { id: 10, spot: 5 },
        { id: 11, spot: 6 },
        { id: 12, spot: 7 },
        { id: 13, spot: 8 },
      ],
      gridSize: 9,
    },
    {
      id: 4,
      name: "Flat Support",
      projects: [
        { id: 14, spot: 0 },
        { id: 15, spot: 1 },
        { id: 16, spot: 2 },
        { id: 17, spot: 3 },
        { id: 18, spot: 4 },
        { id: 19, spot: 5 },
        { id: 20, spot: 6 },
        { id: 21, spot: 7 },
        { id: 22, spot: 8 },
      ],
      gridSize: 9,
    },
  ],
  projects: [
    {
      categoryId: 1,
      type: "flat" as const,
      product: medardBox,
      thumbnail: pmedard,
    },
    {
      categoryId: 1,
      type: "flat" as const,
      product: champagne,
      thumbnail: pchampagne,
    },
    {
      categoryId: 1,
      type: "flat" as const,
      product: mediflex,
      thumbnail: pmediflex,
    },

    {
      categoryId: 1,
      type: "flat" as const,
      product: deerWhisky,
      thumbnail: pWhiskyDeer,
    },

    {
      categoryId: 1,
      type: "flat" as const,
      product: casque,
      thumbnail: pcasque,
    },
    {
      categoryId: 2,
      product: chateauDeFresnesFlat,
      thumbnail: pChateauFresnes,
    },
    {
      categoryId: 2,
      type: "flat" as const,
      product: kiwanoFlat,
      thumbnail: lbeeranimal,
    },
    {
      categoryId: 2,
      type: "flat" as const,
      product: caviarFlat,
      thumbnail: lcaviar,
    },
    {
      categoryId: 2,
      type: "flat" as const,
      product: explorerFlat,
      thumbnail: lexplore,
    },
    {
      categoryId: 2,
      type: "flat" as const,
      product: lagerBeerFlat,
      thumbnail: lbeer,
    },
    {
      categoryId: 2,
      type: "flat" as const,
      product: vodkaFlat,
      thumbnail: lvodka,
    },
    {
      categoryId: 2,
      type: "flat" as const,
      product: whiteBloomFlat,
      thumbnail: lwhitebeer,
    },
    {
      categoryId: 2,
      type: "flat" as const,
      product: saintJulienFlat,
      thumbnail: lwineblue,
    },
    {
      categoryId: 2,
      type: "flat" as const,
      product: grappeFlat,
      thumbnail: lwinegrape,
    },
    {
      categoryId: 3,
      type: "flat" as const,
      product: celestialFlat,
      thumbnail: fbird,
    },
    {
      categoryId: 3,
      type: "flat" as const,
      product: domusFlat,
      thumbnail: fdumus,
    },
    {
      categoryId: 3,
      type: "flat" as const,
      product: dragonFlat,
      thumbnail: fdragon,
    },
    {
      categoryId: 3,
      type: "flat" as const,
      product: floralArtFlat,
      thumbnail: fflower,
    },
    {
      categoryId: 3,
      type: "flat" as const,
      product: goldenWoodFlat,
      thumbnail: goldenWood,
    },
    {
      categoryId: 3,
      type: "flat" as const,
      product: imagePlusFlat,
      thumbnail: imagePlus,
    },
    {
      categoryId: 3,
      type: "flat" as const,
      product: victoryFlat,
      thumbnail: fexaprintcard,
    },
    {
      categoryId: 3,
      type: "flat" as const,
      product: smileFlat,
      thumbnail: fexaprintlabel,
    },
    {
      categoryId: 3,
      type: "flat" as const,
      product: boulangerieFlat,
      thumbnail: fexaprintmenu,
    },
  ].map((asset, id) => ({ ...asset, id })),
};

export async function BookRepository(
  client: string,
  backend: Backend
): Promise<Book> {
  const id: string = client.split("_")[0];
  switch (client) {
    case "exaprint":
      return exaprintBook;
    case "etac":
      return etacBook;
    case "demo":
      return defaultBook;
    default:
      return backend.getBook(id);
  }
}
