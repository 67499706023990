import classNames from "classnames";
import React from "react";
import styles from "./styles/Bottom.module.scss";

// import menumobile from "../../images/icon/menumobile.svg";
import closemenumobile from "../../images/icon/close.svg";
import tel from "../../images/icon/tel.svg";
import mail from "../../images/icon/mail.svg";
import facebook from "../../images/icon/facebook.svg";
import linkedin from "../../images/icon/linkedin.svg";
import twitter from "../../images/icon/twitter.svg";

const Bottom = ({
  handleToggleVisibleBottom,
  isVisibleBottom,
}: {
  handleToggleVisibleBottom: (
    event: React.SyntheticEvent<Element, Event> | React.KeyboardEvent<Element>
  ) => void;
  isVisibleBottom: Boolean | undefined;
}) => {
  return (
    <>
      <div className={classNames(styles.bottom, "col-12")}></div>
      <div className={classNames(styles.btnmenumobile, "col-12")}>
        {/* <img onClick={(e) => {
            handleToggleVisibleBottom(e);
          }} src={menumobile} alt="Menu mobile"/> */}
      </div>

      <div
        className={classNames(
          styles.menumobile,
          isVisibleBottom ? styles.viewbottom : styles.hidebottom,
          "col-12"
        )}
      >
        <div className={classNames(styles.closemenumobile)}>
          <img
            onClick={(e) => {
              handleToggleVisibleBottom(e);
            }}
            src={closemenumobile}
            alt="close menu mobile"
          />
        </div>
        <div className={classNames(styles.titlezone)}>
          <p>Virtual Book</p>
        </div>
        <div className={classNames(styles.btncontact, "col-12")}>
          <div>
            <a href="mgi-fr.com">
              <div className={classNames(styles.image)}>
                <img src={mail} alt="Contact" />
              </div>
              <p>Contactez nous</p>
            </a>
          </div>
          <div>
            <a href="tel:030123456789">
              <div className={classNames(styles.image)}>
                <img src={tel} alt="Tel" />
              </div>
              <p className={classNames(styles.tel)}>+33 (0)1 23 45 67 89</p>
            </a>
          </div>
        </div>

        <div className={classNames(styles.social, "col-12")}>
          <p>Retrouvez nous ici !</p>
          <div className={classNames(styles.icon, "col-12")}>
            <a href="facebook.com">
              <img src={facebook} alt="Facebook" />
            </a>
            <a href="likedin.com">
              <img src={linkedin} alt="Facebook" />
            </a>
            <a href="twitter.com">
              <img src={twitter} alt="Facebook" />
            </a>
          </div>
          <div className={classNames(styles.brand, "col-12")}>
            <p>
              <a href="mgi-fr.com">mgi-fr.com</a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Bottom;
