import classNames from "classnames";
import React, { SyntheticEvent, useCallback } from "react";
import styles from "./styles/Left3D.module.scss";

import Logomgikm from "../../images/Logo/LogoMGI.png";

// whiteG blackG whiteM blackM whiteT blackT whiteS blackS
import viewfoil from "../../images/icon/visibility.svg";
import hiddenfoil from "../../images/icon/hiddenfoil.svg";
import viewfoilw from "../../images/icon/visibilityw.svg";
import hiddenfoilw from "../../images/icon/hiddenfoilw.svg";
import arrowbottom from "../../images/icon/arrowbottom.svg";
import arrowtop from "../../images/icon/arrowtop.svg";
import Logohb from "../../images/Logo/logohb.svg";
import {
  CreationState,
  FoilColorName,
  FoilMapping,
  LayerView,
  Product,
} from "@mgi-labs/preview";
import { mapFoilNameToFoilImg, mapPaperNameToPaperImg } from "./bottom3D";
import { useTranslation } from "react-i18next";
import { backgroundColors } from "./right3D";
import { Link, useLocation } from "react-router-dom";
import { UseBook } from "../../hooks/UseBook";
import { isharrisbruno } from "../../checkharrisbruno";

const Left3D = ({
  layers,
  setLayers,
  activeLayer,
  setActiveLayer,
  selectedPaperName,
  packaging,
  colorSelectedFoils,
  backgroundColor,
  client,
  isVisibleDevis,
  isVisibleFoilVar,
  setIsVisibleFoilVar,
  isVisibleLeft,
  handleToggleVisibleLeft,
  active360Background,
}: {
  layers: CreationState["layersView"] | undefined;
  setLayers: React.Dispatch<React.SetStateAction<LayerView[]>>;
  activeLayer: string | undefined;
  setActiveLayer: React.Dispatch<React.SetStateAction<string | undefined>>;
  selectedPaperName: string;
  packaging: Product;
  colorSelectedFoils: FoilMapping;
  backgroundColor: String;
  client: string;
  isVisibleDevis: Boolean | undefined;
  isVisibleFoilVar: Boolean | undefined;
  setIsVisibleFoilVar: React.Dispatch<React.SetStateAction<Boolean>>;
  handleToggleVisibleLeft: (
    event: React.SyntheticEvent<Element, Event> | React.KeyboardEvent<Element>
  ) => void;
  isVisibleLeft: Boolean | undefined;
  active360Background: string | undefined;
}) => {
  const book = UseBook(client);

  const { t } = useTranslation();

  const handleDisableLayer = useCallback(
    (event: SyntheticEvent | KeyboardEvent, name?: string) => {
      if (!("key" in event) || event.key === "Enter") {
        const dispatchLayer: LayerView[] = [];
        layers &&
          layers.forEach((l) => {
            if (l.name === name) {
              dispatchLayer.push({ name: l.name, enable: !l.enable });
            } else {
              dispatchLayer.push({ name: l.name, enable: l.enable });
            }
          });
        setLayers(dispatchLayer);
      }
    },
    [layers, setLayers]
  );

  const location = useLocation();

  const isDemo = location.hash.includes("#demo");

  return (
    <div className={classNames(styles.allpage, "col-12")}>
      <div className={classNames(styles.container)}>
        <div className={classNames(styles.content, "col-12 row")}>
          {!isDemo && (
            <div className={classNames(styles.infoentreprise, "col-12 row")}>
              <div className={classNames(styles.logoentreprise, "col-12")}>
                <Link to={`/${client}`}>
                  <img
                    src={
                      book?.logoUrl
                        ? book?.logoUrl
                        : isharrisbruno()
                        ? "/logo.svg"
                        : "/client/" + client + "/logo.svg"
                    }
                    alt="Logo small catalogue"
                  />
                </Link>
              </div>
            </div>
          )}
          <div className={classNames(styles.titlezone, "col-12 row")}>
            <div className={classNames(styles.nameentreprise, "col-12")}></div>
          </div>
          <div
            className={classNames(
              styles.choose,
              isVisibleDevis === undefined
                ? ""
                : isVisibleDevis
                ? styles.devinotvisible
                : styles.divisvisible,
              !isVisibleFoilVar ? "" : styles.clickchoose,
              "col-12 row"
            )}
          >
            <div className={classNames(styles.choosetype, "col-12 row")}>
              <div className={classNames(styles.titlechange, "col-12")}>
                <h2
                  className={classNames(
                    styles.title,
                    backgroundColors.black === backgroundColor
                      ? styles.white
                      : ""
                  )}
                >
                  {isDemo ? "MGI Approve" : packaging.name}
                </h2>
                <div
                  onClick={handleToggleVisibleLeft}
                  className={classNames(
                    active360Background
                      ? styles.arrow_background_opac
                      : styles.arrow_background
                  )}
                >
                  <img
                    src={!isVisibleLeft ? arrowtop : arrowbottom}
                    alt="Arrow"
                  />
                </div>
              </div>
              <div
                className={classNames(
                  active360Background ? styles.changeOpac : styles.change,
                  isVisibleLeft ? styles.viewchoose : styles.hidechoose,
                  !isVisibleLeft
                    ? styles.viewchoosedesktop
                    : styles.hidechoosedesktop,
                  "col-12"
                )}
              >
                {layers &&
                  layers.map(
                    (l, index) =>
                      l.name !== "normalMap" && (
                        <div
                          key={index}
                          className={classNames(styles.typezone, "col-12")}
                        >
                          <div
                            className={classNames(
                              activeLayer === l.name && styles.typeselected,
                              l.name.includes("Foil")
                                ? styles.type
                                : styles.typeColorVarnish,
                              "col-12"
                            )}
                            onClick={(e) => {
                              if (l.name.includes("Foil"))
                                if (activeLayer === l.name) {
                                  setActiveLayer(undefined);
                                } else {
                                  setActiveLayer(l.name);
                                  setIsVisibleFoilVar(true);
                                }
                            }}
                          >
                            {l.name.includes("Foil") && (
                              <img
                                className={classNames(
                                  styles.silvercolor,
                                  styles.gold
                                )}
                                src={mapFoilNameToFoilImg(
                                  colorSelectedFoils[l.name as FoilColorName]
                                    .name
                                )}
                                alt="select silver"
                              />
                            )}

                            <h2
                              className={classNames(
                                backgroundColors.black === backgroundColor
                                  ? styles.white
                                  : ""
                              )}
                            >
                              {t(
                                l.name.includes("Foil")
                                  ? colorSelectedFoils[l.name as FoilColorName]
                                      .name
                                  : l.name
                              )}
                            </h2>
                            <div
                              className={classNames(styles.viewseparation)}
                            />
                          </div>
                          <img
                            onClick={(e) => {
                              handleDisableLayer(e, l.name);
                            }}
                            className={classNames(
                              styles.view,
                              backgroundColors.black === backgroundColor
                                ? styles.invert
                                : ""
                            )}
                            src={
                              backgroundColors.black === backgroundColor
                                ? !l.enable
                                  ? hiddenfoilw
                                  : viewfoilw
                                : !l.enable
                                ? hiddenfoil
                                : viewfoil
                            }
                            alt="Cacher/afficher foil"
                          />
                          {/*IMAGE DE MAXIME <img className={classNames(styles.changeimg)} src={changeimg} alt="Icon change foil color or paper or varnish size"/>*/}
                        </div>
                      )
                  )}
                {/* Séparation entre couche papier et autres couches */}
                <div className={classNames(styles.separation)}></div>
                <div
                  className={classNames(
                    styles.type,
                    styles.papertype,
                    activeLayer === "paper" && styles.typeselected,
                    "col-12"
                  )}
                  onClick={(e) => {
                    if (activeLayer === "paper") {
                      setActiveLayer(undefined);
                    } else {
                      setActiveLayer("paper");
                      setIsVisibleFoilVar(true);
                    }
                  }}
                >
                  <img
                    className={classNames(styles.imgpaper)}
                    src={mapPaperNameToPaperImg(selectedPaperName)}
                    alt="Paper preview"
                  />
                  <h2
                    className={classNames(
                      backgroundColors.black === backgroundColor
                        ? styles.white
                        : ""
                    )}
                  >
                    {t(selectedPaperName)}
                  </h2>
                </div>
                {/* <div className={classNames(styles.fond)}></div>*/}
              </div>
            </div>
          </div>
          {!activeLayer ? (
            <div className={classNames(styles.contactzone, "col-12 row")}>
              <div className={classNames(styles.contact)}>
                <a className={classNames(styles.contactus)} href="./">
                  {isharrisbruno() ? (
                    <img src={Logohb} alt="Logohb" />
                  ) : (
                    <img src={Logomgikm} alt="Logomgikm" />
                  )}
                </a>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Left3D;
