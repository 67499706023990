import { Layout } from "react-grid-layout";
import { Category } from "../Domain/book";

export function gridLayout(index: number, gridSize?: number) {
  switch (gridSize) {
    case 1:
      return {
        x: index,
        y: 0,
        w: 3,
        h: 3,
        static: true,
      };
    case 2:
      return {
        x: index * 1.5,
        y: 0.75,
        w: 1.5,
        h: 1.5,
        static: true,
      };
    case 3:
      return {
        x: index,
        y: 1,
        w: 1,
        h: 1,
        static: true,
      };
    case 4:
      if (index >= 0 && index < 2) {
        return {
          x: index * 1.5,
          y: 0,
          w: 1.5,
          h: 1.5,
          static: true,
        };
      } else {
        return {
          x: (index % 2) * 1.5,
          y: 1.5,
          w: 1.5,
          h: 1.5,
          static: true,
        };
      }

    case 5:
      if (index >= 0 && index < 2) {
        if (index === 0) {
          return {
            x: index,
            y: 0.5,
            w: 2,
            h: 1,
            static: true,
          };
        } else {
          return {
            x: 2,
            y: 0.5,
            w: 1,
            h: 1,
            static: true,
          };
        }
      } else if (index >= 2 && index < 5) {
        return {
          x: (index + 1) % 3,
          y: 1.5,
          w: 1,
          h: 1,
          static: true,
        };
      }
      break;
    case 6:
      if (index >= 0 && index < 3) {
        return {
          x: index % 3,
          y: 0.5,
          w: 1,
          h: 1,
          static: true,
        };
      } else {
        return {
          x: index % 3,
          y: 1.5,
          w: 1,
          h: 1,
          static: true,
        };
      }

    case 7:
      if (index >= 0 && index < 2) {
        if (index === 0) {
          return {
            x: index,
            y: 0,
            w: 2,
            h: 1,
            static: true,
          };
        } else {
          return {
            x: 2,
            y: 0,
            w: 1,
            h: 1,
            static: true,
          };
        }
      } else if (index >= 2 && index < 5) {
        return {
          x: (index + 1) % 3,
          y: 1,
          w: 1,
          h: 1,
          static: true,
        };
      } else if (index === 5) {
        return {
          x: 0,
          y: 2,
          w: 1,
          h: 1,
          static: true,
        };
      } else {
        return {
          x: 1,
          y: 2,
          w: 2,
          h: 1,
          static: true,
        };
      }
    case 8:
      if (index >= 0 && index < 2) {
        if (index === 0) {
          return {
            x: index,
            y: 0,
            w: 2,
            h: 1,
            static: true,
          };
        } else {
          return {
            x: 2,
            y: 0,
            w: 1,
            h: 1,
            static: true,
          };
        }
      } else if (index >= 2 && index < 5) {
        return {
          x: (index + 1) % 3,
          y: 1,
          w: 1,
          h: 1,
          static: true,
        };
      } else {
        return {
          x: (index + 1) % 3,
          y: 2,
          w: 1,
          h: 1,
          static: true,
        };
      }
    case 9:
      if (index >= 0 && index < 3) {
        return {
          x: index % 3,
          y: 0,
          w: 1,
          h: 1,
          static: true,
        };
      } else if (index >= 3 && index < 6) {
        return {
          x: index % 3,
          y: 1,
          w: 1,
          h: 1,
          static: true,
        };
      } else {
        return {
          x: index % 3,
          y: 2,
          w: 1,
          h: 1,
          static: true,
        };
      }
  }
}

export function responsiveGridLayout(category: Category): Layout[] {
  const array = [...Array(category.gridSize)];

  const outputLayout: Layout[] | undefined = [];
  switch (category.gridSize) {
    case 1:
      array?.forEach((_, index) => {
        outputLayout.push({
          i: index.toString(),
          x: index,
          y: 0,
          w: 3,
          h: 3,
          static: true,
        });
      });
      return outputLayout;
    case 2:
      array?.forEach((_, index) => {
        outputLayout.push({
          i: index.toString(),
          x: index * 1.5,
          y: 0.75,
          w: 1.5,
          h: 1.5,
          static: true,
        });
      });
      return outputLayout;
    case 3:
      array?.forEach((_, index) => {
        outputLayout.push({
          i: index.toString(),
          x: index,
          y: 1,
          w: 1,
          h: 1,
          static: true,
        });
      });
      return outputLayout;
    case 4:
      array?.forEach((_, index) => {
        if (index >= 0 && index < 2) {
          outputLayout.push({
            i: index.toString(),
            x: index * 1.5,
            y: 0,
            w: 1.5,
            h: 1.5,
            static: true,
          });
        } else {
          outputLayout.push({
            i: index.toString(),
            x: (index % 2) * 1.5,
            y: 1.5,
            w: 1.5,
            h: 1.5,
            static: true,
          });
        }
      });
      return outputLayout;
    case 5:
      array?.forEach((_, index) => {
        if (index >= 0 && index < 2) {
          if (index === 0) {
            outputLayout.push({
              i: index.toString(),
              x: index,
              y: 0.5,
              w: 2,
              h: 1,
              static: true,
            });
          } else {
            outputLayout.push({
              i: index.toString(),
              x: 2,
              y: 0.5,
              w: 1,
              h: 1,
              static: true,
            });
          }
        } else if (index >= 2 && index < 5) {
          outputLayout.push({
            i: index.toString(),
            x: (index + 1) % 3,
            y: 1.5,
            w: 1,
            h: 1,
            static: true,
          });
        }
      });
      return outputLayout;

    case 6:
      array?.forEach((_, index) => {
        if (index >= 0 && index < 3) {
          outputLayout.push({
            i: index.toString(),
            x: index % 3,
            y: 0.5,
            w: 1,
            h: 1,
            static: true,
          });
        } else {
          outputLayout.push({
            i: index.toString(),
            x: index % 3,
            y: 1.5,
            w: 1,
            h: 1,
            static: true,
          });
        }
      });
      return outputLayout;

    case 7:
      array?.forEach((_, index) => {
        if (index >= 0 && index < 2) {
          if (index === 0) {
            outputLayout.push({
              i: index.toString(),
              x: 0,
              y: 0,
              w: 2,
              h: 1,
              static: true,
            });
          } else {
            outputLayout.push({
              i: index.toString(),
              x: 2,
              y: 0,
              w: 1,
              h: 1,
              static: true,
            });
          }
        } else if (index >= 2 && index < 5) {
          outputLayout.push({
            i: index.toString(),
            x: (index + 1) % 3,
            y: 1,
            w: 1,
            h: 1,
            static: true,
          });
        } else if (index === 5) {
          outputLayout.push({
            i: index.toString(),
            x: 0,
            y: 2,
            w: 1,
            h: 1,
            static: true,
          });
        } else if (index === 6) {
          outputLayout.push({
            i: index.toString(),
            x: 1,
            y: 2,
            w: 2,
            h: 1,
            static: true,
          });
        }
      });
      return outputLayout;

    case 8:
      array?.forEach((_, index) => {
        if (index >= 0 && index < 2) {
          if (index === 0) {
            outputLayout.push({
              i: index.toString(),
              x: index,
              y: 0,
              w: 2,
              h: 1,
              static: true,
            });
          } else {
            outputLayout.push({
              i: index.toString(),
              x: 2,
              y: 0,
              w: 1,
              h: 1,
              static: true,
            });
          }
        } else if (index >= 2 && index < 5) {
          outputLayout.push({
            i: index.toString(),
            x: (index + 1) % 3,
            y: 1,
            w: 1,
            h: 1,
            static: true,
          });
        } else {
          outputLayout.push({
            i: index.toString(),
            x: (index + 1) % 3,
            y: 2,
            w: 1,
            h: 1,
            static: true,
          });
        }
      });
      return outputLayout;
    case 9:
      array?.forEach((_, index) => {
        if (index >= 0 && index < 3) {
          outputLayout.push({
            i: index.toString(),
            x: index % 3,
            y: 0,
            w: 1,
            h: 1,
            static: true,
          });
        } else if (index >= 3 && index < 6) {
          outputLayout.push({
            i: index.toString(),
            x: index % 3,
            y: 1,
            w: 1,
            h: 1,
            static: true,
          });
        } else {
          outputLayout.push({
            i: index.toString(),
            x: index % 3,
            y: 2,
            w: 1,
            h: 1,
            static: true,
          });
        }
      });
      return outputLayout;
    default:
      return outputLayout;
  }
}

export function tabletGridLayout(category: Category): Layout[] {
  const array = [...Array(category.gridSize)];

  const outputLayout: Layout[] | undefined = [];
  switch (category.gridSize) {
    case 1:
      array?.forEach((_, index) => {
        outputLayout.push({
          i: index.toString(),
          x: index,
          y: 1,
          w: 2,
          h: 2,
          static: true,
        });
      });
      return outputLayout;
    case 2:
      array?.forEach((_, index) => {
        if (index === 0) {
          outputLayout.push({
            i: index.toString(),
            x: index,
            y: 0,
            w: 2,
            h: 2,
            static: true,
          });
        } else {
          outputLayout.push({
            i: index.toString(),
            x: index,
            y: 2,
            w: 2,
            h: 2,
            static: true,
          });
        }
      });
      return outputLayout;
    case 3:
      array?.forEach((_, index) => {
        if (index === 0) {
          outputLayout.push({
            i: index.toString(),
            x: index,
            y: 0,
            w: 2,
            h: 1.33,
            static: true,
          });
        } else if (index === 1) {
          outputLayout.push({
            i: index.toString(),
            x: index,
            y: 1.33,
            w: 2,
            h: 1.33,
            static: true,
          });
        } else {
          outputLayout.push({
            i: index.toString(),
            x: index,
            y: 2.66,
            w: 2,
            h: 1.33,
            static: true,
          });
        }
      });
      return outputLayout;
    case 4:
      array?.forEach((_, index) => {
        if (index >= 0 && index < 2) {
          outputLayout.push({
            i: index.toString(),
            x: index,
            y: 0.5,
            w: 1,
            h: 1.5,
            static: true,
          });
        } else {
          outputLayout.push({
            i: index.toString(),
            x: index % 2,
            y: 2,
            w: 1,
            h: 1.5,
            static: true,
          });
        }
      });
      return outputLayout;
    case 5:
      array?.forEach((_, index) => {
        if (index === 0) {
          outputLayout.push({
            i: index.toString(),
            x: index,
            y: 0,
            w: 2,
            h: 1.33,
            static: true,
          });
        } else if (index >= 1 && index < 3) {
          outputLayout.push({
            i: index.toString(),
            x: (index + 1) % 2,
            y: 1.33,
            w: 1,
            h: 1.33,
            static: true,
          });
        } else if (index >= 3) {
          outputLayout.push({
            i: index.toString(),
            x: (index + 1) % 2,
            y: 2.66,
            w: 1,
            h: 1.33,
            static: true,
          });
        }
      });
      return outputLayout;

    case 6:
      array?.forEach((_, index) => {
        if (index >= 0 && index < 2) {
          outputLayout.push({
            i: index.toString(),
            x: index % 2,
            y: 0,
            w: 1,
            h: 1.33,
            static: true,
          });
        } else if (index >= 2 && index < 4) {
          outputLayout.push({
            i: index.toString(),
            x: index % 2,
            y: 1.33,
            w: 1,
            h: 1.33,
            static: true,
          });
        } else {
          outputLayout.push({
            i: index.toString(),
            x: index % 2,
            y: 2.66,
            w: 1,
            h: 1.33,
            static: true,
          });
        }
      });
      return outputLayout;

    case 7:
      array?.forEach((_, index) => {
        if (index === 0) {
          outputLayout.push({
            i: index.toString(),
            x: index,
            y: 0,
            w: 2,
            h: 1,
            static: true,
          });
        } else if (index >= 1 && index < 3) {
          outputLayout.push({
            i: index.toString(),
            x: (index + 1) % 2,
            y: 1,
            w: 1,
            h: 1,
            static: true,
          });
        } else if (index >= 3 && index < 5) {
          outputLayout.push({
            i: index.toString(),
            x: (index + 1) % 2,
            y: 2,
            w: 1,
            h: 1,
            static: true,
          });
        } else {
          outputLayout.push({
            i: index.toString(),
            x: (index + 1) % 2,
            y: 3,
            w: 1,
            h: 1,
            static: true,
          });
        }
      });
      return outputLayout;

    case 8:
      array?.forEach((_, index) => {
        if (index >= 0 && index < 2) {
          outputLayout.push({
            i: index.toString(),
            x: index % 2,
            y: 0,
            w: 1,
            h: 1,
            static: true,
          });
        } else if (index >= 2 && index < 4) {
          outputLayout.push({
            i: index.toString(),
            x: index % 2,
            y: 1,
            w: 1,
            h: 1,
            static: true,
          });
        } else if (index >= 4 && index < 6) {
          outputLayout.push({
            i: index.toString(),
            x: index % 2,
            y: 2,
            w: 1,
            h: 1,
            static: true,
          });
        } else if (index >= 6 && index < 8) {
          outputLayout.push({
            i: index.toString(),
            x: index % 2,
            y: 3,
            w: 1,
            h: 1,
            static: true,
          });
        }
      });
      return outputLayout;
    case 9:
      array?.forEach((_, index) => {
        if (index >= 0 && index < 2) {
          outputLayout.push({
            i: index.toString(),
            x: index % 2,
            y: 0,
            w: 1,
            h: 1,
            static: true,
          });
        } else if (index >= 2 && index < 4) {
          outputLayout.push({
            i: index.toString(),
            x: index % 2,
            y: 1,
            w: 1,
            h: 1,
            static: true,
          });
        } else if (index >= 4 && index < 6) {
          outputLayout.push({
            i: index.toString(),
            x: index % 2,
            y: 2,
            w: 1,
            h: 1,
            static: true,
          });
        } else if (index >= 6 && index < 8) {
          outputLayout.push({
            i: index.toString(),
            x: index % 2,
            y: 3,
            w: 1,
            h: 1,
            static: true,
          });
        }
      });
      return outputLayout;
    default:
      return outputLayout;
  }
}
