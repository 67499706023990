import { Flat } from "@mgi-labs/preview";

const magnoliaFlat: Flat = new Flat({
  name: "Magnolia",
  paper: {
    layer: { url: "BlackSatin.png", name: "paper" },
  },
  design: {
    recto: {
      color: {
        layer: {
          url: "color.png",
          url_SD: "color.png",
          name: "color",
          width: 1536,
          height: 2048,
        },
      },
      varnish: {
        layer: { url: "varnish.png", url_SD: "varnish.png", name: "Varnish" },
      },
    },
    verso: {
      color: {
        layer: {
          url: "color2.png",
          url_SD: "color2.png",
          name: "color",
          width: 1536,
          height: 2048,
        },
      },
      varnish: {
        layer: { url: "varnish2.png", url_SD: "varnish2.png", name: "Varnish" },
      },
    },
    mask: {
      url: "mask.png",
      width: 850,
      height: 1134,
    },
  },
});

export default magnoliaFlat;
