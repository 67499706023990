import {
  autoFoldConverter,
  Flat,
  Packaging,
  parseProductDesignFromLayers,
} from "@mgi-labs/preview";
import { Asset, Book, defaultBook } from "../Domain/book";
import { AssetDto, BookDto } from "../Domain/book.type";
import StaticPackagingRepository from "../products/StaticPackagingRepository";
import { UTMData } from "./ChoixProjet";

// TODO: Move file to src/backend folder

export type ContactDto = {
  assetName: string;
  copyCount?: number;
  email: string;
  companyName: string;
  foil1Type?: string;
  foil2Type?: string;
  paperType?: string;
  phoneNumber?: string;
};

export default class Backend {
  constructor(
    public readonly rootUrl: string,
    private readonly fetch: typeof window.fetch = (...args) =>
      window.fetch(...args)
  ) {}

  async notifyBookOpen(utm: UTMData) {
    await this.fetch(this.expandURL("/tracking"), {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(utm),
    });
  }

  async notifyProjectOpen(projectId: number, bookId: string) {
    await this.fetch(this.expandURL("/tracking/projects"), {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ projectId: projectId, bookId: bookId }),
    });
  }

  async getBook(bookId: string): Promise<Book> {
    const response = await this.fetch(this.expandURL("/books/" + bookId));
    const { book } = (await response.json()) as { book: BookDto };
    if (!book) {
      return defaultBook;
    }
    return {
      id: book.id,
      name: book.name,
      static: false,
      primaryColor: book.primaryColor,
      secondaryColor: book.secondaryColor,
      url: book.url,
      share: book.share,
      logoUrl: book.logoUrl,
      category: book.category,
      projects: book.projects && mapContentToAsset(book.projects),
    };
  }

  async sendContact(bookdId: string, contact: ContactDto): Promise<Response> {
    const url = this.expandURL(`books/${bookdId}/send-quote-request-email`);
    return await this.fetch(url.toString(), {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(contact),
    });
  }

  private expandURL(url: string): string {
    return this.rootUrl ? new URL(url, this.rootUrl).toString() : url;
  }
}

function mapContentToAsset(assets: AssetDto[]): Asset[] {
  const contents: (Asset | undefined)[] = assets.map((a) => {
    const design =
      a.designLayers && parseProductDesignFromLayers(a.designLayers);
    if (design instanceof Error) {
      console.log(a, "Error on design");
      return undefined;
    }

    const experimentalAutoFoldDieline = a.autoFold
      ? autoFoldConverter(a.autoFold)
      : new Error("Could not convert custom autofold to a dieline");

    if (a.packagingModel === "static") {
      const p = StaticPackagingRepository(a.name);
      return {
        id: a.id,
        categoryId: a.categoryId,
        product: p,
        thumbnailColor: a.thumbnailColor,
        name: a.name,
        thumbnailForBook: a.thumbnailForBook,
        type: a.packagingModel,
      };
    }

    if (
      experimentalAutoFoldDieline &&
      !(experimentalAutoFoldDieline instanceof Error) &&
      a.packagingModel === "custom"
    ) {
      const p = new Packaging({
        dieline: experimentalAutoFoldDieline,
        initial3dRotation: [0, 0, 0],
        design,
        name: a.name,
        paper: { layer: { url: "EskaDuoWhite.png" } },
        useMask: true,
      });
      return {
        id: a.id,
        categoryId: a.categoryId,
        product: p,
        thumbnailColor: a.thumbnailColor,
        thumbnailForBook: a.thumbnailForBook,
        name: a.name,
        type: a.packagingModel,
      };
    } else {
      const product = new Flat({
        design,
        width: a.width,
        height: a.height,
        paper: {
          layer: { url: "BlackSatin.png", name: "paper" },
        },
        name: a.name,
      });
      return {
        id: a.id,
        categoryId: a.categoryId,
        product: product,
        thumbnailColor: a.thumbnailColor,
        thumbnailForBook: a.thumbnailForBook,
        name: a.name,
        type: a.packagingModel,
      };
    }
  });
  return contents.filter((item): item is Asset => item !== undefined);
}
