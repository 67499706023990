import {
  CreationState,
  LayerView,
  PaperMaterial,
  ProductDesign,
} from "@mgi-labs/preview";
import { mapBottleNameToBottleObject } from "@mgi-labs/preview/dist/Domain/Bottle";
import {
  DEFAULT_FOIL_MAPPING,
  FoilMapping,
} from "@mgi-labs/preview/dist/Domain/Foil";
import "bootstrap/dist/css/bootstrap.min.css";
import classNames from "classnames";
import React, {
  KeyboardEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useLocation, useParams } from "react-router-dom";
import { Book } from "../../Domain/book";
import { BookBottleName, book_BOTTLE_NAMES } from "../../Domain/bottle";
import { UseBook } from "../../hooks/UseBook";
import aucune from "../../images/icon/aucune.svg";
import {
  default as bordeau,
  default as champagne,
} from "../../images/icon/bordeau.svg";
import bourgogne from "../../images/icon/bourgogne.svg";
import closebottle from "../../images/icon/closebottle.svg";
import alsace from "../../images/icon/elsass.svg";
import moovelabel from "../../images/icon/moovelabel.svg";
import more from "../../images/icon/more.svg";
import off from "../../images/icon/off.svg";
import on from "../../images/icon/on.svg";
import StaticPackagingRepository from "../../products/StaticPackagingRepository";
import {
  createProductFromBook,
  ProductContent,
} from "../../repositories/ProductRepository";
import Loader3D from "../Loader3D";
import { Preview3D } from "../Preview3D";
import Bottom3D from "./bottom3D";
import Labelposition from "./Labelposition";
import Left3D from "./left3D";
import { paperPicker } from "./paper";
import Right3D, { backgroundColors } from "./right3D";
import styles from "./styles/Home.module.scss";

export const PreviewSceneContainer = () => {
  const { projectId, projectName } = useParams<{
    projectId: string;
    projectName: string;
  }>();
  const location = useLocation();
  const client = location.pathname.split("/")[1].split("_")[0];
  const book = UseBook(client);

  const packaging = book
    ? book.static
      ? StaticPackagingRepository(projectName)
      : createProductFromBook(book, Number(projectId))
    : undefined;

  return (
    <PreviewScene
      book={book}
      key={projectName}
      packaging={packaging}
      client={client}
    />
  );
};

const PreviewScene = ({
  packaging,
  client,
  book,
}: {
  packaging?: ProductContent;
  client: string;
  book: Book | undefined;
}) => {
  const [layers, setLayers] = useState<LayerView[]>([]);

  useEffect(() => {
    setLayers(createLawerView(packaging?.design));
  }, [packaging?.design]);

  const [backgroundColor, setBackgroundColor] = useState<string>(
    backgroundColors.grey
  );

  const [activeLayer, setActiveLayer] = useState<string>();

  const [selectedPaper, setSelectedPaper] = useState<PaperMaterial>(
    paperPicker.WhiteSatin
  );

  const [colorSelectedFoils, setColorSelectedFoils] =
    useState<FoilMapping>(DEFAULT_FOIL_MAPPING);

  const [isVisibleDevis] = useState<Boolean>();
  // const handleToggleDevis = useCallback(
  //   (event: SyntheticEvent | KeyboardEvent) => {
  //     if (!("key" in event) || event.key === "Enter") {
  //       setIsVisibleDevis((isVisibleDevi) => !isVisibleDevi);
  //     }
  //   },
  //   []
  // );
  const [isVisibleLeft, setIsVisibleLeft] = useState<Boolean>();
  const handleToggleLeft = useCallback(
    (event: SyntheticEvent | KeyboardEvent) => {
      if (!("key" in event) || event.key === "Enter") {
        setIsVisibleLeft((isVisibleLef) => !isVisibleLef);
      }
    },
    []
  );

  const [selectedBottle, setSelectedBottle] =
    useState<BookBottleName>("Aucune");

  const height = packaging?.height;

  const [showOnBottle, setShowOnBottle] = useState<boolean>(false);
  const handleShowOnBottle = useCallback(
    (event: SyntheticEvent | KeyboardEvent) => {
      if (!("key" in event) || event.key === "Enter") {
        setShowOnBottle((showOnBottle) => !showOnBottle);
      }
    },
    []
  );
  const [isVisibleFoilVar, setIsVisibleFoilVar] = useState<Boolean>(false);

  const [isMobile, setIsMobile] = useState<Boolean>(window.innerWidth <= 576);
  useEffect(() => {
    window.addEventListener("resize", () =>
      setIsMobile(window.innerWidth <= 576)
    );
  }, []);

  const [isVisibleBottleModule, setVisibleBottleModule] =
    useState<boolean>(false);

  const bottleObject = mapBottleNameToBottleObject(selectedBottle);

  const [labelPosition, setLabelPosition] = useState<number | undefined>(
    bottleObject ? bottleObject.defaultLabelPosition : undefined
  );

  const [folded, setFolded] = useState<boolean>(
    !(packaging?.type === "custom")
  );

  const location = useLocation();
  const isDemo = location.hash.includes("#demo");

  const [active360Background, setActive360Background] = useState<
    string | undefined
  >(isDemo ? "vignes" : undefined);

  if (packaging === undefined) {
    return null;
  }
  const previewState: CreationState = {
    packaging: packaging,
    smartLabels: [],
    cancelImport: false,
    layersView: layers ?? [],
    annotations: [],
    enableZoom: true,
  };

  const mobileBottleModule = (
    <div className={classNames(styles.bottle)}>
      {book_BOTTLE_NAMES.map((bottleName, i) => {
        return (
          <div
            key={i}
            className={classNames(styles.bottle_button)}
            onClick={(e) => {
              !showOnBottle && handleShowOnBottle(e);
              setSelectedBottle(bottleName);
            }}
          >
            <img
              className={classNames(
                selectedBottle === bottleName ? styles.selectedbottle : ""
              )}
              src={mapBottleNameToImage(bottleName)}
              alt="bottle"
            />
          </div>
        );
      })}
    </div>
  );

  return (
    <>
      <div
        className={classNames(
          styles.home,
          isVisibleDevis === undefined
            ? ""
            : isVisibleDevis
            ? styles.divisvisible
            : styles.devinotvisible,
          "col-12"
        )}
      >
        <Loader3D backgroundColor={backgroundColor} />
        <Preview3D
          previewState={previewState}
          colorSelectedFoils={colorSelectedFoils}
          selectedPaper={selectedPaper}
          backgroundColor={backgroundColor}
          isVisibleDevis={isVisibleDevis}
          showOnBottle={showOnBottle}
          selectedBottle={selectedBottle}
          labelPosition={labelPosition}
          folded={folded}
          active360Background={active360Background}
        />
        <div className={classNames(styles.container, "container")}>
          <div className={classNames(styles.principal, "col-12 row")}>
            <div className={classNames(styles.choosetype, "col-2 row")}>
              <Left3D
                backgroundColor={backgroundColor}
                layers={layers}
                setLayers={setLayers}
                activeLayer={activeLayer}
                setActiveLayer={setActiveLayer}
                selectedPaperName={selectedPaper.name}
                packaging={packaging}
                colorSelectedFoils={colorSelectedFoils}
                client={client}
                isVisibleDevis={isVisibleDevis}
                isVisibleFoilVar={isVisibleFoilVar}
                setIsVisibleFoilVar={setIsVisibleFoilVar}
                isVisibleLeft={isVisibleLeft}
                handleToggleVisibleLeft={handleToggleLeft}
                active360Background={active360Background}
              ></Left3D>
            </div>
            <div className={classNames(styles.choosetype, "col-8 row")}></div>
            <div className={classNames(styles.filtershare, "col-2 row")}>
              {packaging.type === "bottleLabel" &&
                (isMobile ? (
                  mobileBottleModule
                ) : (
                  <div className={classNames(styles.bottlemodule)}>
                    <div className={classNames(styles.bottletopzone, "row")}>
                      <p>Bottle</p>
                      <div className={classNames(styles.button)}>
                        {showOnBottle ? (
                          <img
                            onClick={() => {
                              setSelectedBottle("Aucune");
                              setShowOnBottle(!showOnBottle);
                              setVisibleBottleModule(false);
                            }}
                            className={classNames(styles.on)}
                            src={on}
                            alt="Show bottle"
                          />
                        ) : (
                          <img
                            onClick={() => {
                              setSelectedBottle("Bordeaux");
                              setShowOnBottle(!showOnBottle);
                            }}
                            className={classNames(styles.on)}
                            src={off}
                            alt="Hide bottle"
                          />
                        )}
                        {isVisibleBottleModule ? (
                          <img
                            onClick={() =>
                              setVisibleBottleModule(!isVisibleBottleModule)
                            }
                            className={classNames(styles.close)}
                            src={closebottle}
                            alt="Choose bottle"
                          />
                        ) : (
                          <img
                            onClick={() =>
                              setVisibleBottleModule(!isVisibleBottleModule)
                            }
                            className={classNames(styles.more)}
                            src={more}
                            alt="Choose bottle"
                          />
                        )}
                      </div>
                    </div>
                    <div
                      className={classNames(
                        isVisibleBottleModule
                          ? styles.bottlebottomzone
                          : styles.displaynone
                      )}
                    >
                      <div className={classNames(styles.moovelabel)}>
                        <img src={moovelabel} alt="Moovelabel" />
                        <Labelposition
                          selectedBottle={selectedBottle}
                          labelHeight={height}
                          setLabelPosition={setLabelPosition}
                          labelPosition={labelPosition}
                        />
                      </div>
                      <div className={classNames(styles.allbottle)}>
                        {book_BOTTLE_NAMES.map((bottleName) => {
                          return (
                            bottleName !== "Aucune" && (
                              <div
                                className={classNames(styles.bottle_button)}
                                onClick={(e) => {
                                  !showOnBottle && handleShowOnBottle(e);
                                  setSelectedBottle(bottleName);
                                }}
                              >
                                <img
                                  className={classNames(
                                    selectedBottle === bottleName
                                      ? styles.selectedbottle
                                      : ""
                                  )}
                                  src={mapBottleNameToImage(bottleName)}
                                  alt="bottle"
                                />
                              </div>
                            )
                          );
                        })}
                      </div>
                    </div>
                    <div className={classNames(styles.bottle)}>
                      {book_BOTTLE_NAMES.map((bottleName) => {
                        return (
                          <div
                            className={classNames(styles.bottle_button)}
                            onClick={(e) => {
                              bottleName === "Aucune"
                                ? setShowOnBottle(false)
                                : !showOnBottle && handleShowOnBottle(e);
                              setSelectedBottle(bottleName);
                            }}
                          >
                            <img
                              className={classNames(
                                selectedBottle === bottleName
                                  ? styles.selectedbottle
                                  : ""
                              )}
                              src={mapBottleNameToImage(bottleName)}
                              alt="bottle"
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ))}

              <Right3D
                backgroundColor={backgroundColor}
                setBackgroundColor={setBackgroundColor}
                // client={client}
                isVisibleDevis={isVisibleDevis}
                // handleToggleVisibleDevis={handleToggleDevis}
                book={book}
                packaging={packaging}
                folded={folded}
                setFolded={setFolded}
                active360Background={active360Background}
                setActive360Background={setActive360Background}
              />
            </div>
            {activeLayer && (
              <Bottom3D
                activeLayer={activeLayer}
                colorSelectedFoils={colorSelectedFoils}
                setColorSelectedFoils={setColorSelectedFoils}
                selectedPaper={selectedPaper}
                setSelectedPaper={setSelectedPaper}
                isVisibleFoilVar={isVisibleFoilVar}
                setIsVisibleFoilVar={setIsVisibleFoilVar}
                setActiveLayer={setActiveLayer}
                isVisibleLeft={isVisibleLeft}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

function isDefinedLayer(x: LayerView | undefined): Boolean {
  return x?.enable !== false;
}

function createLawerView(design: ProductDesign | undefined): LayerView[] {
  let layerList: LayerView[] = [];
  if (design) {
    layerList = [
      {
        name: "color",
        enable:
          design.recto.color.layer !== undefined ||
          design?.verso?.color.layer !== undefined,
      },

      {
        name: "displacementMap",
        enable:
          design?.recto.displacementMap?.layer !== undefined ||
          design?.verso?.displacementMap?.layer !== undefined,
      },
      {
        name: "goldFoil",
        enable:
          design?.recto.goldFoil?.layer !== undefined ||
          design?.verso?.goldFoil?.layer !== undefined,
      },
      {
        name: "holoFoil",
        enable:
          design?.recto.holoFoil?.layer !== undefined ||
          design?.verso?.holoFoil?.layer !== undefined,
      },
      {
        name: "silverFoil",
        enable:
          design?.recto.silverFoil?.layer !== undefined ||
          design?.verso?.silverFoil?.layer !== undefined,
      },
      {
        name: "copperFoil",
        enable:
          design?.recto.copperFoil?.layer !== undefined ||
          design?.verso?.copperFoil?.layer !== undefined,
      },
      {
        name: "redFoil",
        enable:
          design?.recto.redFoil?.layer !== undefined ||
          design?.verso?.redFoil?.layer !== undefined,
      },
      {
        name: "greenFoil",
        enable:
          design?.recto.greenFoil?.layer !== undefined ||
          design?.verso?.greenFoil?.layer !== undefined,
      },
      {
        name: "blueFoil",
        enable:
          design?.recto.blueFoil?.layer !== undefined ||
          design?.verso?.blueFoil?.layer !== undefined,
      },
      {
        name: "pinkFoil",
        enable:
          design?.recto.pinkFoil?.layer !== undefined ||
          design?.verso?.pinkFoil?.layer !== undefined,
      },
      {
        name: "orangeFoil",
        enable:
          design?.recto.orangeFoil?.layer !== undefined ||
          design?.verso?.orangeFoil?.layer !== undefined,
      },
      {
        name: "blackFoil",
        enable:
          design?.recto.blackFoil?.layer !== undefined ||
          design?.verso?.blackFoil?.layer !== undefined,
      },
      {
        name: "varnish",
        enable:
          design?.recto.varnish?.layer !== undefined ||
          design?.verso?.varnish?.layer !== undefined,
      },
      {
        name: "normalMap",
        enable:
          design?.recto.normalMap?.layer !== undefined ||
          design?.verso?.normalMap?.layer !== undefined,
      },
    ].filter(isDefinedLayer);
  }
  return layerList;
}

function mapBottleNameToImage(bottleName: string) {
  switch (bottleName) {
    case "Aucune":
      return aucune;
    case "Alsace":
      return alsace;
    case "Bordeaux":
      return bordeau;
    case "Bourgogne":
      return bourgogne;
    case "Champagne":
      return champagne;
  }
}
