import { Flat } from "@mgi-labs/preview";

const etacCerf: Flat = new Flat({
  name: "etacCerf",
  paper: {
    layer: { url: "BlackSatin.png", name: "paper" },
  },
  design: {
    recto: {
      color: {
        layer: {
          url: "color.png",
          url_SD: "color.png",
          name: "color",
          width: 2048,
          height: 1598,
        },
      },
      goldFoil: {
        layer: {
          url: "goldFoil.png",
          url_SD: "goldFoil.png",
          name: "Foil Gold",
        },
      },
    },
  },
});

export default etacCerf;
