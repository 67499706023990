import { mapBottleNameToBottleObject } from "@mgi-labs/preview/dist/Domain/Bottle";
import classNames from "classnames";
import React, { useEffect, useMemo } from "react";
import { BookBottleName } from "../../Domain/bottle";
import styles from "./styles/Home.module.scss";

const Labelposition = ({
  selectedBottle,
  labelPosition,
  setLabelPosition,
  labelHeight,
}: {
  selectedBottle: BookBottleName;
  labelPosition: number | undefined;
  setLabelPosition: React.Dispatch<number>;
  labelHeight: number | undefined;
}) => {
  const bottleObject = mapBottleNameToBottleObject(selectedBottle);

  useEffect(() => {
    bottleObject && setLabelPosition(bottleObject.defaultLabelPosition);
  }, [bottleObject, selectedBottle, setLabelPosition]);

  const yStart = useMemo(() => {
    return labelPosition;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBottle, labelPosition]);

  const yEnd = useMemo(() => {
    if (labelHeight && bottleObject) {
      return bottleObject.bottleLabelMaxPosition - labelHeight / 10.0;
    } else return selectedBottle === "Bordeaux" ? 9.0 : 3.0;
  }, [bottleObject, labelHeight, selectedBottle]);

  return (
    <>
      <div className={classNames(styles.labelposition)}>
        <input
          type="range"
          name="petiquette"
          id="petiquette"
          min="1"
          max={yEnd}
          value={yStart}
          onChange={(e) => {
            setLabelPosition(parseFloat(e.target.value));
          }}
          step="0.1"
        />
      </div>
    </>
  );
};
export default Labelposition;
