import {
  BlankPackaging,
  CustomizedPackaging,
  deriveRotationAxes,
  DielineJson,
} from "@mgi-labs/preview";

export const minimalBlankFoldingDieline: DielineJson = {
  name: "medardbox",
  dimensions: { width: 729.021, height: 474.667 },
  faceGeometries: {
    back: {
      height: 173.548,
      width: 233.532,
      x: 64.637,
      y: 163.363,
    },
    bot: {
      height: 87.912,
      width: 233.397,
      x: 388.345,
      y: 336.911,
    },
    front: {
      height: 173.548,
      width: 233.532,
      x: 388.345,
      y: 163.363,
    },
    left: {
      height: 173.548,
      width: 90.293,
      x: 298.169,
      y: 163.363,
    },
    right: {
      height: 173.548,
      width: 90.293,
      x: 621.88,
      y: 163.363,
    },
    top: {
      height: 87.912,
      width: 233.397,
      x: 388.345,
      y: 75.441,
    },
  },
  foldingTree: {
    faceName: "bot",
    children: [
      {
        faceName: "front",
        children: [
          { faceName: "top" },
          { faceName: "right" },
          { faceName: "left", children: [{ faceName: "back" }] },
        ],
      },
    ],
  },
};

const blankMedardBox: BlankPackaging = new BlankPackaging({
  dieline: deriveRotationAxes(minimalBlankFoldingDieline),
  paper: {
    layer: { url: "BlackSatin.png", name: "paper" },
  },
});

const medardBox: CustomizedPackaging = new CustomizedPackaging({
  dieline: blankMedardBox.dieline,
  name: "medardBox",
  paper: blankMedardBox.paper,
  design: {
    color: {
      layer: { url: "color.png", url_SD: "color.png" },
    },
    goldFoil: {
      layer: { url: "goldFoil.png", url_SD: "goldFoil.png", name: "Foil Gold" },
    },
    varnish: {
      layer: { url: "varnish.png", url_SD: "varnish.png", name: "Varnish" },
    },
  },
});

export default medardBox;
