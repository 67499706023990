import "bootstrap/dist/css/bootstrap.min.css";
import classNames from "classnames";
import React, {
  createRef,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useGesture } from "react-use-gesture";
import { Handler } from "react-use-gesture/dist/types";
import { useBackend } from "../../backend/BackendProvider";
import { Book } from "../../Domain/book";
import { UseBook } from "../../hooks/UseBook";
import "../../Internationalization/i18n";
import Bottom from "./bottom";
import Left from "./left";
import Projects from "./projects";
import Right from "./right";
import styles from "./styles/Home.module.scss";

const { sign, abs } = Math;

const WHEEL_THRESHOLD = 25;

export type UTMData = {
  utmSource: string;
  utmMedium: string;
  utmCampaign: string;
  contactId: string;
};

export function ChoixProjetContainer() {
  const { category, clientName } = useParams<{
    category?: string;
    clientName: string;
  }>();

  const location = useLocation();
  const client = location.pathname !== "/" ? clientName : "/demo";
  const book = UseBook(client);

  return book ? (
    <ChoixProjet
      book={book}
      categoryId={category ? parseInt(category) : book.category[0].id}
      client={client}
    />
  ) : null;
}

const ChoixProjet = ({
  book,
  categoryId,
  client,
}: {
  book: Book;
  categoryId: number;
  client: string;
}) => {
  const [currentCategoryId, setCurrentCategoryId] =
    useState<number>(categoryId);

  const location = useLocation();
  const history = useHistory();

  const backend = useBackend();

  useEffect(() => {
    function extractUtm(): UTMData | undefined {
      const utmParams = new URLSearchParams(location.search);
      const utmSource = utmParams.get("utm_source");
      const utmMedium = utmParams.get("utm_medium");
      const utmCampaign = utmParams.get("utm_campaign");
      const contactId = utmParams.get("contact_id");

      if (utmSource && utmMedium && utmCampaign && contactId) {
        return {
          utmSource: utmSource,
          utmMedium: utmMedium,
          utmCampaign: utmCampaign,
          contactId,
        };
      } else return undefined;
    }

    const utm = extractUtm();
    utm && backend.notifyBookOpen(utm);
  }, [backend, location.search]);

  const [isMobile, setIsMobile] = useState<Boolean>(window.innerWidth <= 576);
  useEffect(() => {
    window.addEventListener("resize", () =>
      setIsMobile(window.innerWidth <= 576)
    );
  }, []);

  const categRefs: {
    [categoryId: number]: React.RefObject<HTMLDivElement>;
  } = useMemo(() => {
    const refs: typeof categRefs = {};
    book.category.forEach((category) => {
      refs[category.id] = createRef<HTMLDivElement>();
    });
    return refs;
  }, [book.category]);

  const largeScreenScrollDivRef = useRef<HTMLDivElement>(null);
  const mobileScrollDivRef = useRef<HTMLDivElement>(null);

  const scrollDivRef = useMemo(
    () => (isMobile ? mobileScrollDivRef : largeScreenScrollDivRef),
    [isMobile]
  );

  // Prevent scroll when true
  const lockWheel = useRef(false);

  const activeProjectRef = categRefs[currentCategoryId];

  const scrollToProjectType = useCallback(
    (categoryId: number) => {
      const category = book.category?.find(
        (category) => category.id === categoryId
      );
      const categoryRef = categRefs[categoryId];
      if (category && categoryRef?.current && !lockWheel.current) {
        setCurrentCategoryId(categoryId);
        history.replace("/" + client + "/" + categoryId + "-" + category.name);
      }
    },
    [book.category, categRefs, client, history]
  );

  useEffect(() => {
    if (activeProjectRef?.current) {
      scrollDivRef.current?.scrollTo({
        top: activeProjectRef?.current?.offsetTop,
        behavior: "smooth",
      });
    }
  }, [
    activeProjectRef,
    categoryId,
    scrollDivRef,
    scrollToProjectType,
    currentCategoryId,
  ]);

  // How much we scrolled since the start of the movement
  const y = useRef(0);

  const triggerScrollIfIdleOrDecelerating: Handler<"wheel"> = ({
    delta: [, dy],
    direction: [, direction],
    memo: previousDy = 0,
  }) => {
    if (!isMobile) {
      const isDecelerating =
        sign(dy) === sign(previousDy) && abs(dy) - abs(previousDy) <= 0;
      if (isDecelerating) {
        lockWheel.current = false;
        y.current = 0;
      } else if (!lockWheel.current) {
        if (abs(y.current) >= WHEEL_THRESHOLD) {
          scrollToNextProjectType(direction);
          lockWheel.current = true;
          y.current = 0;
        }
        y.current = y.current - dy;
      }
      return dy;
    }
  };

  // Smart detection of scroll to another project type
  // Inspired by the following code:
  // https://codesandbox.io/s/react-use-gesture-wheel-clamp-urdco?file=/src/index.js:1442-1543
  const wheel = useGesture({
    onWheel: triggerScrollIfIdleOrDecelerating,
    onDrag: (props) => {
      // Invert scroll direction when dragging
      const [dirX, dirY] = props.direction;
      return triggerScrollIfIdleOrDecelerating({
        ...props,
        direction: [dirX, -dirY],
      });
    },
    onWheelEnd: () => {
      if (lockWheel.current) {
        lockWheel.current = false;
        y.current = 0;
      }
    },
  });

  const scrollToNextProjectType = useCallback(
    (direction: number) => {
      if (largeScreenScrollDivRef.current) {
        const categoryIndex = book.category.findIndex(
          (category) => category.id === categoryId
        );
        if (categoryIndex === undefined) {
          throw new Error(`Invalid category id: ${categoryId}`);
        }

        if (direction > 0) {
          const nextCategory = book.category[categoryIndex + 1];
          if (nextCategory) {
            scrollToProjectType(nextCategory.id);
          }
        } else {
          const prevCategory = book.category[categoryIndex - 1];
          if (prevCategory) {
            scrollToProjectType(prevCategory.id);
          }
        }
      }
    },
    [book.category, categoryId, scrollToProjectType]
  );

  const [isVisibleBottom, setIsVisibleBottom] = useState<Boolean>();
  const handleToggleBottom = useCallback(
    (event: SyntheticEvent | KeyboardEvent) => {
      if (!("key" in event) || event.key === "Enter") {
        setIsVisibleBottom((isVisibleBot) => !isVisibleBot);
      }
    },
    []
  );

  return (
    <>
      <div className={classNames(styles.home, "col-12")}>
        <div className={classNames(styles.container, "container")}>
          <div className={classNames(styles.principal, "col-12 row")}>
            <div
              className={classNames(
                styles.choosetype,
                "col-0 col-sm-1 col-md-2 row"
              )}
            >
              {book && (
                <Left
                  selectedProjectType={currentCategoryId}
                  scrollToProjectType={scrollToProjectType}
                  client={client}
                  book={book}
                ></Left>
              )}
            </div>
            <div
              className={classNames(
                styles.content,
                "col-12 col-sm-10 col-md-8 row"
              )}
              ref={largeScreenScrollDivRef}
              {...wheel()}
            >
              {book && (
                <Projects
                  categRefs={categRefs}
                  client={client}
                  book={book}
                  isMobile={isMobile}
                  setIsMobile={setIsMobile}
                  mobileScrollDivRef={mobileScrollDivRef}
                ></Projects>
              )}
            </div>
            <div
              className={classNames(
                styles.filtershare,
                "col-0 col-sm-1 col-md-2 row"
              )}
            >
              {book && <Right book={book} />}
            </div>
            <Bottom
              isVisibleBottom={isVisibleBottom}
              handleToggleVisibleBottom={handleToggleBottom}
            ></Bottom>
          </div>
        </div>
      </div>
    </>
  );
};
export default ChoixProjetContainer;
