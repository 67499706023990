import {
  BlankPackaging,
  CustomizedPackaging,
  deriveRotationAxes,
  DielineJson,
} from "@mgi-labs/preview";

const pixelPerInch = 150;

// Coordinates in inches here, to have coordinates that are resolution-independent
// TODO: maybe convert to points for more precision

// Note: all other face geometries are calculated from the front and bottom
// geometries
const front: DielineJson["faceGeometries"][string] = {
  x: 5.05 * pixelPerInch,
  y: 3.5 * pixelPerInch,
  width: 1.76 * pixelPerInch,
  height: 5.9 * pixelPerInch,
};
const bot: DielineJson["faceGeometries"][string] = {
  x: front.x,
  y: front.y + front.height,
  width: front.width,
  height: 1.83 * pixelPerInch,
};

const faceGeometries: DielineJson["faceGeometries"] = {
  front,
  bot,
  left: {
    x: front.x - bot.height,
    y: front.y,
    width: bot.height,
    height: front.height,
  },
  top: {
    x: front.x,
    y: front.y - bot.height,
    width: front.width,
    height: bot.height,
  },
  back: {
    x: front.x - bot.height - front.width,
    y: front.y,
    width: front.width,
    height: front.height,
  },
  right: {
    x: front.x + front.width,
    y: front.y,
    width: bot.height,
    height: front.height,
  },
};

export const blankBeYou: BlankPackaging = new BlankPackaging({
  dieline: deriveRotationAxes({
    name: "beYou",
    dimensions: { height: 1925, width: 1429 },
    faceGeometries,
    foldingTree: {
      faceName: "bot",
      children: [
        {
          faceName: "front",
          children: [
            { faceName: "right" },
            { faceName: "left", children: [{ faceName: "back" }] },
            { faceName: "top" },
          ],
        },
      ],
    },
  }),
});

export const beYou4: CustomizedPackaging = new CustomizedPackaging({
  dieline: blankBeYou.dieline,
  name: "beYou4",
  paper: {
    layer: { url: "BlackSatin.png", name: "paper" },
  },
  design: {
    color: {
      layer: { url: "color.png", url_SD: "color.png", name: "color" },
    },
    goldFoil: {
      layer: { url: "goldFoil.png", url_SD: "goldFoil.png", name: "Foil Gold" },
    },
    silverFoil: {
      layer: {
        url: "silverFoil.png",
        url_SD: "silverFoil.png",
        name: "Foil silver",
      },
    },
    varnish: {
      layer: { url: "varnish.png", url_SD: "varnish.png", name: "Varnish" },
    },
  },
});
