import classNames from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Book, Category } from "../../Domain/book";
import styles from "./styles/Projects.module.scss";
import { WidthProvider, Responsive } from "react-grid-layout";
import { tabletGridLayout, responsiveGridLayout } from "../gridLayout";
import { useBackend } from "../../backend/BackendProvider";

const ReactGridLayout = WidthProvider(Responsive);

const Projects = ({
  categRefs,
  book,
  isMobile,
  setIsMobile,
  mobileScrollDivRef,
}: {
  categRefs: { [categoryId: number]: React.RefObject<HTMLDivElement> };
  mobileScrollDivRef: React.RefObject<HTMLDivElement>;
  client: string;
  book: Book;
  isMobile: Boolean;
  setIsMobile: React.Dispatch<React.SetStateAction<Boolean>>;
}) => {
  const location = useLocation();
  const client = location.pathname;
  useEffect(() => {
    window.addEventListener("resize", () =>
      setIsMobile(window.innerWidth <= 576)
    );
  }, [setIsMobile]);

  const contentRef = useRef<HTMLDivElement>(null);

  const [rowHeight, setRowHeight] = useState<number>(80);

  useEffect(() => {
    if (contentRef.current) {
      const nodeStyle = window.getComputedStyle(contentRef.current);
      const paddingBottom = nodeStyle.getPropertyValue("padding-bottom");
      contentRef.current?.clientHeight &&
        setRowHeight(
          window.innerWidth <= 992
            ? contentRef.current?.clientHeight / 4 - parseInt(paddingBottom)
            : contentRef.current?.clientHeight / 3 - parseInt(paddingBottom)
        );
      window.addEventListener("resize", () => {
        contentRef.current?.clientHeight &&
          setRowHeight(
            window.innerWidth <= 992
              ? contentRef.current?.clientHeight / 4 - parseInt(paddingBottom)
              : contentRef.current?.clientHeight / 3 - parseInt(paddingBottom)
          );
      });
    }
  }, [contentRef]);

  const backend = useBackend();
  const handleClickProject = useCallback(
    (book: string, project: number) => {
      if (project && book) {
        backend.notifyProjectOpen(project, book);
      }
    },
    [backend]
  );

  const content = (category: Category) => {
    return [...Array(category.gridSize)].map((_, i) => {
      const item = category.projects?.find((v) => v.spot === i);

      const project = book.projects?.find((p) => p.id === item?.id);

      const name = project?.product.name?.replaceAll("%", "%25");

      return (
        <div key={project?.id ?? i} className={classNames(styles.projet)}>
          {
            <div className={classNames(styles.image, "col-12")}>
              {project && (
                <Link to={`${client}/creation/${project.id}/${name}`}>
                  <img
                    src={
                      project.thumbnailForBook
                        ? project.thumbnailForBook
                        : project.thumbnailColor
                    }
                    alt="Logo small catalogue"
                  />
                </Link>
              )}
            </div>
          }
        </div>
      );
    });
  };

  return (
    <>
      <div className={classNames(styles.toptitle)}>
        <p>{book.name}</p>
      </div>
      <div
        className={classNames(
          isMobile ? styles.allpagemobile : styles.allpage,
          "col-12"
        )}
        ref={mobileScrollDivRef}
      >
        <div className={classNames(styles.toptransparence)}></div>

        {book.category?.map((category) => (
          <div
            key={category.id}
            className={classNames(styles.container)}
            id={category.name}
            ref={categRefs[category.id]}
          >
            <div className={classNames(styles.content)} ref={contentRef}>
              {!isMobile ? (
                <ReactGridLayout
                  className="layout"
                  breakpoints={{ lg: 1200, md: 996, sm: 596, xs: 435, xxs: 0 }}
                  cols={{ lg: 3, md: 3, sm: 3, xs: 3, xxs: 2 }}
                  rowHeight={rowHeight}
                  layouts={{
                    lg: responsiveGridLayout(category),
                    md: responsiveGridLayout(category),
                    sm: responsiveGridLayout(category),
                    xs: responsiveGridLayout(category),
                    xxs: tabletGridLayout(category),
                  }}
                >
                  {
                    // book.projects
                    //   ?.filter((asset) =>
                    //     category.projects?.map((i) => i.id === asset.id)
                    //   )
                    //   .map((asset) => {
                    [...Array(category.gridSize)].map((_, i) => {
                      const item = category.projects?.find((v) => v.spot === i);

                      const project = book.projects?.find(
                        (p) => p.id === item?.id
                      );

                      const name = project?.product.name?.replaceAll(
                        "%",
                        "%25"
                      );

                      return (
                        <div
                          key={i}
                          className={classNames(
                            styles.projet,
                            project && styles.projetHover
                          )}
                        >
                          {
                            <div
                              className={classNames(
                                project && styles.image,
                                "col-12"
                              )}
                            >
                              {project && (
                                <Link
                                  onClick={() =>
                                    handleClickProject(book.id, project.id)
                                  }
                                  to={`${client}/creation/${project.id}/${name}`}
                                >
                                  <img
                                    src={
                                      project.thumbnailForBook
                                        ? project.thumbnailForBook
                                        : project.thumbnailColor
                                    }
                                    alt="Logo small catalogue"
                                  />
                                </Link>
                              )}
                            </div>
                          }
                        </div>
                      );
                    })
                  }
                </ReactGridLayout>
              ) : (
                content(category)
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Projects;
