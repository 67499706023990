import {
  BlankPackaging,
  CustomizedPackaging,
  deriveRotationAxes,
  DielineJson,
} from "@mgi-labs/preview";

export const minimalBlankFoldingDieline: DielineJson = {
  name: "champagne",
  dimensions: { width: 908.333, height: 1073.82 },
  faceGeometries: {
    back: {
      height: 541.667,
      width: 250.434,
      x: 79.566,
      y: 288.737,
    },
    bot: {
      height: 149.96,
      width: 250.434,
      x: 483.847,
      y: 830.404,
    },
    front: {
      height: 541.667,
      width: 250.434,
      x: 483.847,
      y: 288.737,
    },
    left: {
      height: 541.667,
      width: 153.847,
      x: 330,
      y: 288.737,
    },
    right: {
      height: 541.667,
      width: 153.847,
      x: 734.281,
      y: 288.737,
    },
    top: {
      height: 149.96,
      width: 250.434,
      x: 483.847,
      y: 137.985,
    },
  },
  foldingTree: {
    faceName: "bot",
    children: [
      {
        faceName: "front",
        children: [
          { faceName: "top" },
          { faceName: "right" },
          { faceName: "left", children: [{ faceName: "back" }] },
        ],
      },
    ],
  },
};

const blankChampagne: BlankPackaging = new BlankPackaging({
  dieline: deriveRotationAxes(minimalBlankFoldingDieline),
  paper: {
    layer: { url: "BlackSatin.png", name: "paper" },
  },
});

const champagne: CustomizedPackaging = new CustomizedPackaging({
  dieline: blankChampagne.dieline,
  name: "champagne",
  paper: blankChampagne.paper,
  design: {
    color: {
      layer: { url: "color.png", url_SD: "color.png" },
    },
    goldFoil: {
      layer: { url: "goldFoil.png", url_SD: "goldFoil.png", name: "Foil Gold" },
    },
    varnish: {
      layer: { url: "varnish.png", url_SD: "varnish.png", name: "Varnish" },
    },
  },
});

export default champagne;
